var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-toolbar",
        { attrs: { inverted: "" } },
        [
          _c("q-toolbar-title", [
            _vm._v("\n      Kategorie\n      "),
            _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v(
                "\n        wybór przypisanych do materiału kategorii\n      "
              )
            ])
          ])
        ],
        1
      ),
      _vm._l(_vm.thisCategories, function(kategoria) {
        return _c(
          "q-list",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dataItems.length > 0,
                expression: "dataItems.length>0"
              }
            ],
            key: kategoria
          },
          [
            _c("q-list-header", [_vm._v(_vm._s(kategoria))]),
            _vm._l(_vm.thisGroups(kategoria), function(item) {
              return _c(
                "q-item",
                { key: item._oid },
                [
                  _c(
                    "q-item-side",
                    [
                      _c("q-toggle", {
                        attrs: { "true-value": 1, "false-value": 0 },
                        on: {
                          input: function($event) {
                            return _vm.categoryChange(item)
                          }
                        },
                        model: {
                          value: item.kchecked,
                          callback: function($$v) {
                            _vm.$set(item, "kchecked", $$v)
                          },
                          expression: "item.kchecked"
                        }
                      })
                    ],
                    1
                  ),
                  _c("q-item-main", {
                    attrs: { label: item.grupa, sublabel: item.rola }
                  })
                ],
                1
              )
            })
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }