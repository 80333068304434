<template>
    <div class="row no-wrap justify-between items-start content-stretch" v-if="getAppMode_ICOR">
      <div :class="classLeftRow">
          <q-tabs animated swipeable :refs="$refs" align="justify" no-pane-border>
            <q-tab default name="tab-1" slot="title" icon="device_hub">
              <q-tooltip>Serwisy i aplikacje</q-tooltip>
              <q-popover v-model="showHelp" :disable="!showHelp">
                <h3>Serwisy i aplikacje</h3>
                <p>Drzewo aplikacji, rozdziały i linki.</p>
                <p>Każdy użytkownik widzi tylko to, do czego ma uprawnienia</p>
              </q-popover>
            </q-tab>
            <q-tab name="tab-2" slot="title" icon="extension"><q-tooltip>Repozytorium</q-tooltip></q-tab>
            <q-tab name="tab-3" slot="title" icon="build"><q-tooltip>System</q-tooltip></q-tab>
            <q-tab name="tab-4" slot="title" icon="reorder"><q-tooltip>Zakładki</q-tooltip></q-tab>
            <q-tab-pane name="tab-1" keep-alive>
              <iframe id="tocpane" :src="urlIFrameTOC" class="myframe" frameborder="0" width="100%" marginheight="0" marginwidth="0" scrolling="auto"></iframe>
            </q-tab-pane>
            <q-tab-pane name="tab-2" keep-alive>
              <iframe id="Iframe1conf" :src="urlIFrameConf" class="myframe" frameborder="0" width="100%" marginheight="0" marginwidth="0" scrolling="auto"></iframe>
            </q-tab-pane>
            <q-tab-pane name="tab-3" keep-alive>
              <iframe id="Iframe1system" :src="urlIFrameSystem" class="myframe" frameborder="0" width="100%" marginheight="0" marginwidth="0" scrolling="auto"></iframe>
            </q-tab-pane>
            <q-tab-pane name="tab-4" keep-alive>
              <card-favorite :tabs="tabs"></card-favorite>
            </q-tab-pane>
          </q-tabs>
      </div>
      <div class="col">
          <q-tabs animated swipeable :refs="$refs" v-model="tabs.selected" align="left" no-pane-border two-lines>
            <q-tab v-for="item in tabs.items" :key="item.name" slot="title" :name="item.name" :label="item.label?decodeHTML(item.label):'Dane'">
              <div class="accept_click q-chip row no-wrap inline items-center q-chip-floating q-chip-dense q-chip-square tab-chip" v-if="getShow_TabCloseButton">
                <div @click.stop="clickTabClose(item.name)" class="q-chip-main">X</div>
              </div>
              <q-tooltip><span v-html="item.label"></span><div v-if="item.sublabel!==''"><br><span v-if="item.actionlabel">{{item.actionlabel}} - </span><span v-html="item.sublabel"></span></div></q-tooltip>
            </q-tab>
            <q-tab-pane v-for="item in tabs.items" :key="item.name" :name="item.name" keep-alive  class="q-pa-none">
              <tab-manager :tabitem="item" v-if="item.tabtype===''"></tab-manager>
              <stats :tabitem="item" v-if="item.tabtype==='/stats/'"></stats>
              <uzytkownikdane :tabitem="item" v-if="item.tabtype==='/user/settings/'"></uzytkownikdane>
              <card-settings :tabitem="item" v-if="item.tabtype==='/app/settings/'"></card-settings>
            </q-tab-pane>
          </q-tabs>
      </div>
      <q-modal ref="modalfilemanager" class="maximized" :content-css="{padding: '50px'}" @open="notifyFMModal('open')" @escape-key="notifyFMModal('escape-key')" @close="notifyFMModal('close')">
        <iframe :src="isrc" class="modal-iframe"></iframe>
        <button class="tertiary" @click="$refs.modalfilemanager.close()">Close Me</button>
      </q-modal>
  </div>
</template>

<script type="text/javascript">
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
// import cardAttachments from './cardAttachments.vue'
// import cardInfo from './cardInfo.vue'
// import cardOptions from './cardOptions.vue'
// import cardMultimedia from './cardMultimedia.vue'
// import cardGeo from './cardGeo.vue'
// import cardAccessHistory from './cardAccessHistory.vue'
// import cardVersionHistory from './cardVersionHistory.vue'
import cardFavorite from './cardFavorite.vue'
import cardSettings from './cardSettings.vue'
import tabManager from './tabManager.vue'
import stats from '../stats/stats.vue'
import uzytkownikdane from './uzytkownikdane.vue'

export default {
  name: 'ICORManager',
  data () {
    return {
      isrc: '',
      tabStates: {},
      tabs: {
        selected: 'tab-b-1',
        cnt: 1,
        items: []
      }
    }
  },
  components: {
    // cardAttachments,
    // cardInfo,
    // cardOptions,
    // cardMultimedia,
    // cardGeo,
    // cardAccessHistory,
    // cardVersionHistory,
    cardSettings,
    cardFavorite,
    tabManager,
    stats,
    uzytkownikdane
  },
  created () {
    this.$store.dispatch('initLocalParameters')
    this.$root.$on('open-filemanager', this.openFileManager)
    this.$root.$on('change-ICOR-URL', this.changeICORURL)
    this.$root.$on('logout', this.logout)
    this.$root.$on('click-LastVisitHistory', this.clickLastVisitHistory)
    this.$root.$on('click-CloseActiveTab', this.clickCloseActiveTab)
    this.$root.$on('click-AddNewTab', this.clickAddNewTab)
    this.$root.$on('click-RefreshActiveTab', this.clickRefreshActiveTab)
    this.$root.$on('set-manager-tab-info', this.setManagerTabInfo)
    this.$root.$on('remove-manager-tab', this.removeManagerTab)
    this.$root.$on('tab-setNewTabRoute', this.tabSetNewTabRoute)
    if (window.addEventListener) {
      window.addEventListener('message', this.listenWindowMessage, false)
    } else {
      window.attachEvent('onmessage', this.listenWindowMessage)
    }
    let dd = Vue.vueDragula.find('attachments-bag')
    if (typeof dd === 'undefined') {
      Vue.vueDragula.options('attachments-bag', {
        direction: 'vertical',
        moves: (el, source, handle, sibling) => {
          return handle.classList.contains('handle')
        }
      })
      Vue.vueDragula.options('multimedia-bag', {
        direction: 'vertical',
        moves: (el, source, handle, sibling) => {
          return handle.classList.contains('handle')
        }
      })
      Vue.vueDragula.options('tabs-bag', {
        direction: 'vertical',
        moves: (el, source, handle, sibling) => {
          return handle.classList.contains('handle')
        }
      })
    }
  },
  beforeDestroy () {
    this.$root.$off('open-filemanager', this.openFileManager)
    this.$root.$off('change-ICOR-URL', this.changeICORURL)
    this.$root.$off('logout', this.logout)
    this.$root.$off('click-LastVisitHistory', this.clickLastVisitHistory)
    this.$root.$off('click-CloseActiveTab', this.clickCloseActiveTab)
    this.$root.$off('click-AddNewTab', this.clickAddNewTab)
    this.$root.$off('click-RefreshActiveTab', this.clickRefreshActiveTab)
    this.$root.$off('set-manager-tab-info', this.setManagerTabInfo)
    this.$root.$off('remove-manager-tab', this.removeManagerTab)
    this.$root.$off('tab-setNewTabRoute', this.tabSetNewTabRoute)
    if (window.addEventListener) {
      window.removeEventListener('message', this.listenWindowMessage, false)
    } else {
      window.detachEvent('onmessage', this.listenWindowMessage)
    }
  },
  mounted () {
    Vue.vueDragula.eventBus.$on(
      'drop',
      (args) => {
        // console.log('drop: ' + args[0])
        // console.log(this.objectAttachments)
      }
    )
    Vue.vueDragula.eventBus.$on(
      'dropModel',
      (args) => {
        let tab = this.getActiveTab(false)
        if (args[0] === 'attachments-bag') {
          this.$nextTick(function () {
            this.$root.$emit('drop-attachments', {
              tab: tab
            })
          })
          return
        }
        if (args[0] === 'multimedia-bag') {
          this.$nextTick(function () {
            this.$root.$emit('drop-multimedia', {
              tab: tab
            })
          })
        }
      }
    )
  },
  computed: {
    ...mapGetters(['getICORURL', 'getAppMode_ICOR', 'getUser_UserName', 'getLeftRows', 'getShow_Help', 'getShow_TabCloseButton', 'getShow_LinksInNewTab', 'getShow_LinksTabSelect']),
    showHelp: {
      get: function () {
        return this.getShow_Help
      },
      set: function (newValue) {
        // console.log('setshowhelp: ' + newValue)
        this.setShow_Help(false)
      }
    },
    leftRows: {
      get: function () {
        return this.getLeftRows
      },
      set: function (newValue) {
        // console.log('setshowhelp: ' + newValue)
      }
    },
    classLeftRow () {
      let d = {}
      d['col-' + this.leftRows] = true
      return d
    },
    urlIFrameTOC () {
      if (this.getUser_UserName === '') {
        return ''
      }
      return `${this.getICORURL}/icormanager/contents20.asp`
    },
    urlIFrameConf () {
      if (this.getUser_UserName === '') {
        return ''
      }
      return `${this.getICORURL}/icormanager/contents20conf.asp`
    },
    urlIFrameSystem () {
      if (this.getUser_UserName === '') {
        return ''
      }
      return `${this.getICORURL}/icormanager/contents20system.asp`
    }
  },
  methods: {
    ...mapMutations(['setShow_Help']),
    decodeHTML (value) {
      return this.$icor.data.decodeHTML(value)
    },
    openFileManager (event) {
      // this.fm_oidref = '' // event.oidref || ''
      // this.fm_poid = -1 // event.poid || -1
      // this.$refs.modalfilemanager.open()
      this.changeICORURL({
        url: this.getICORURL + '/icormanager/jslib/rich-filemanager/index.html?__nt=1' // ifm.html'
      })
    },
    listenWindowMessage (event) {
      let tab = this.getActiveTab(false)
      if (event.data.type === 'setIframeTextSrc') {
        this.$nextTick(function () {
          this.$root.$emit('change-ICOR-URL', {
            url: event.data.href
          })
        })
        return
      }
      if (event.data.type === 'manageAttachments') {
        alert('manageAttachments: ' + event.data.oidref + ' - ' + event.data.poid)
        this.$root.$emit('open-filemanager', {
          oidref: event.data.oidref,
          poid: event.data.poid
        })
        return
      }
      if ((event.data.type === 'setDBDataViewStart') && (tab !== null)) {
        if (event.data.ownerTab !== '') {
          let ntab = this.getTabByName(event.data.ownerTab)
          if (ntab !== null) {
            tab = ntab
          }
        }
        this.$nextTick(function () {
          this.$root.$emit('tab-setDBDataViewStart', {
            tab: tab,
            data: event.data
          })
        })
        return
      }
      if (event.data.type === 'setJobType') {
        if (event.data.ownerTab !== '') {
          let ntab = this.getTabByName(event.data.ownerTab)
          if (ntab !== null) {
            tab = ntab
          }
        }
        this.$nextTick(function () {
          this.$root.$emit('tab-setJobTypeStart', {
            tab: tab,
            data: event.data
          })
        })
        return
      }
      if (event.data.type === 'registerStateBadOK') {
        this.tabStates[event.data.aid] = event.data.aid
        this.$q.notify({type: 'info', position: 'bottom-right', message: 'Rozpoczęło się przetwarzanie zlecenia o identyfikatorze ' + event.data.aid})
        setTimeout(this.eventStateChecker.bind(this), 2000)
      }
    },
    async eventStateChecker () {
      var astateid, w
      w = 0
      for (astateid in this.tabStates) {
        w = 1
        let [name, value] = await this.$icor.main.stateGet(astateid)
        if (value === 'OK') {
          try {
            delete this.tabStates[astateid]
          } catch (ex) {
          }
          this.$q.notify({type: 'positive', position: 'bottom-right', message: 'Zlecenie zakończyło się sukcesem. Identyfikator: ' + astateid + '<br>' + name})
        }
        if (value === 'BAD') {
          try {
            delete this.tabStates[astateid]
          } catch (ex) {
          }
          this.$q.notify({type: 'negative', position: 'bottom-right', message: 'Zlecenie zakończyło się niepowodzeniem. Identyfikator: ' + astateid + '<br>' + name})
        }
        if (value === 'DEL') {
          try {
            delete this.tabStates[astateid]
          } catch (ex) {
          }
          this.$q.notify({type: 'warning', position: 'bottom-right', message: 'Zlecenie nie istnieje. Identyfikator: ' + astateid})
        }
      }
      if (w === 1) {
        setTimeout(this.eventStateChecker.bind(this), 4000)
      }
    },
    async logout () {
      let status = await this.$icor.main.logout()
      if (status === 0) {
        this.$store.dispatch('setUser', {})
        this.$router.push('/login')
      }
      // Toast.create.negative('Wystąpił błąd w komunikacji. Skontaktuj się z administratorem.')
    },
    notifyFMModal (mode) {
      if (mode === 'open') {
        this.isrc = this.getICORURL + '/icormanager/jslib/rich-filemanager/index.html' // ifm.html
      }
      if (mode === 'escape-key') {
      }
      if (mode === 'close') {
        this.isrc = ''
      }
    },
    removeTabByIndex (tabindex) {
      let changetab = this.tabs.items[tabindex].name === this.tabs.selected
      this.tabs.items.splice(tabindex, 1)
      if (changetab && (this.tabs.items.length > 0)) {
        this.$nextTick(function () {
          if (tabindex >= this.tabs.items.length) {
            tabindex--
          }
          this.tabs.selected = this.tabs.items[tabindex].name
        })
      }
    },
    removeTabByID (tabid) {
      for (var i in this.tabs.items) {
        if (this.tabs.items[i].id === tabid) {
          this.removeTabByIndex(i)
          break
        }
      }
    },
    removeTabByName (tabname) {
      for (var i in this.tabs.items) {
        if (this.tabs.items[i].name === tabname) {
          this.removeTabByIndex(i)
          break
        }
      }
    },
    addNewTab (url = '', aicon = '', tabtype = '', tabid = '', tabdata = {}) {
      if (url === '') {
        url = 'startpage20q.asp'
      } else {
        url = this.$icor.data.urlRemoveRandomQuery(url)
      }
      if (aicon === '') {
        aicon = 'description'
      }
      let ti = this.tabs.cnt
      this.tabs.cnt += 1
      let alabel = 'Zakładka nr ' + ti
      let asublabel = ''
      if (tabdata.title) {
        // contentTitle: 'Statystyki',
        // contentSubtitle: 'dane o bazie danych',
        // title: 'Lista aktywnych zadań <small>(PG)</small>',
        alabel = tabdata.title
        asublabel = tabdata.contentSubtitle
      }
      let dt = {
        id: ti,
        name: 'ltab_' + ti,
        label: alabel,
        sublabel: asublabel,
        actionlabel: '',
        icon: aicon,
        url: '', // url
        tabtype: tabtype,
        tabid: tabid,
        tabdata: tabdata
      }
      // window.addEventListener
      this.tabs.items.push(dt)
      if (this.getShow_LinksTabSelect || (this.tabs.items.length === 1)) {
        this.tabs.selected = dt.name
        this.$nextTick(function () {
          window.dispatchEvent(new Event('resize'))
        })
      }
      return dt
    },
    getTabByName (tabname) {
      for (var i in this.tabs.items) {
        if (this.tabs.items[i].name === tabname) {
          return this.tabs.items[i]
        }
      }
      return null
    },
    getTabByURL (url) {
      url = this.$icor.data.urlRemoveRandomQuery(url)
      for (var i in this.tabs.items) {
        if (this.tabs.items[i].url === url) {
          return this.tabs.items[i]
        }
      }
      return null
    },
    getActiveTab (anew = true, aurl = '', anoiframe = false) {
      let dt = null
      if (aurl !== '') {
        dt = this.getTabByURL(aurl)
        if (dt !== null) {
          this.tabs.selected = dt.name
        }
      }
      if (dt === null) {
        if (this.tabs.items.length > 0) {
          dt = this.getTabByName(this.tabs.selected)
        }
        if (anoiframe && (dt !== null) && dt.tabtype) {
          dt = this.addNewTab(aurl)
        }
        if ((dt === null) && anew) {
          dt = this.addNewTab(aurl)
        }
      }
      return dt
    },
    removeManagerTab (event) {
      for (var i in this.tabs.items) {
        if (this.tabs.items[i].id === event.tab.id) {
          this.tabs.items.splice(i, 1)
          if (this.tabs.items.length > 0) {
            if (i >= this.tabs.items.length) {
              i--
            }
            this.tabs.selected = this.tabs.items[i].name
          }
          break
        }
      }
    },
    setManagerTabInfo (event) {
      let tab = this.getTabByName(event.tab.name)
      if (tab === null) {
        return
      }
      if (event.label !== undefined) {
        this.$set(tab, 'label', event.label)
      }
      if (event.sublabel !== undefined) {
        this.$set(tab, 'sublabel', event.sublabel)
      }
      if (event.icon !== undefined) {
        this.$set(tab, 'icon', event.icon)
      }
      if (event.url !== undefined) {
        this.$set(tab, 'url', event.url)
      }
      if (event.actionlabel !== undefined) {
        this.$set(tab, 'actionlabel', event.actionlabel)
      }
    },
    changeICORURL (event) {
      // console.log('changeICORURL')
      // console.log(event)
      let tab = null
      let newtab = event.url.search('__nt=1') >= 0
      let url = this.$icor.data.urlRemoveQuery(event.url, '__nt')
      if (newtab && this.getShow_LinksInNewTab) {
        tab = this.addNewTab(url)
      } else {
        tab = this.getActiveTab(true, url, true)
      }
      this.$nextTick(function () {
        this.$root.$emit('tab-setURL', {
          tab: tab,
          url: url
        })
      })
    },
    tabSetNewTabRoute (event) {
      this.addNewTab('', '', event.routetype, event.routeid, event.data)
    },
    clickLastVisitHistory () {
      this.changeICORURL({url: '/icormanager/icormain.asp?jobtype=lastvisithistory&id=searchform'})
      this.$q.notify({type: 'info', position: 'bottom-right', message: 'Powrót do ostatniej wyszukiwarki lub widoku rekordu.'})
    },
    clickCloseActiveTab (event) {
      let tab = this.getActiveTab(false)
      if (tab !== null) {
        this.removeTabByID(tab.id)
      }
    },
    clickAddNewTab () {
      let tab = this.addNewTab()
      this.$nextTick(function () {
        this.$root.$emit('tab-setURL', {
          tab: tab,
          url: tab.url
        })
      })
    },
    clickRefreshActiveTab () {
      let tab = this.getActiveTab(false)
      if (tab !== null) {
        this.$nextTick(function () {
          this.$root.$emit('tab-setURL', {
            tab: tab,
            url: tab.url
          })
        })
      }
    },
    clickTabClose (tabname) {
      let tab = this.getTabByName(tabname)
      if (tab !== null) {
        this.removeTabByID(tab.id)
      }
    }
  }
}
</script>

<style scoped>
.x_content {
   overflow: auto;
   width: 100%;
   height: 80vh;
   margin: 0;
}

.myframe {
   overflow:hidden;
   height:80vh;
   width:100%;
}

.modal-iframe {
  width: 100%;
  height: 100%;
}

.q-tab {
  text-transform: none !important;
}
.q-tabs-head {
  font-size: 0.72rem !important;
}

.accept_click {
  /*
  z-index: 10000 !important;
  user-select: all !important;
  */
  pointer-events: auto !important;
}

.tab-chip {
  top: 1px !important;
  right: 1px !important;
}
</style>
