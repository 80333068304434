var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    ref: "myframe",
    staticClass: "myframe",
    attrs: {
      frameborder: "0",
      width: "100%",
      marginheight: "0",
      marginwidth: "0",
      scrolling: "auto"
    },
    on: {
      load: function($event) {
        return _vm.frameSrcChange($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }