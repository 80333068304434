import { render, staticRenderFns } from "./cardIFrame.vue?vue&type=template&id=9627b572&scoped=true&"
import script from "./cardIFrame.vue?vue&type=script&lang=js&"
export * from "./cardIFrame.vue?vue&type=script&lang=js&"
import style0 from "./cardIFrame.vue?vue&type=style&index=0&id=9627b572&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9627b572",
  null
  
)

component.options.__file = "cardIFrame.vue"
export default component.exports