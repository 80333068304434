var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-toolbar",
    [
      _c("q-ajax-bar", { attrs: { color: "#80cbc4" } }),
      _vm.$router.currentRoute.meta.backButton
        ? _c("q-btn", {
            attrs: { flat: "", icon: "arrow_back" },
            on: {
              click: function($event) {
                return _vm.$router.push("/")
              }
            }
          })
        : _vm._e(),
      _vm.getAppMode_ICOR
        ? _c("q-btn", {
            staticClass: "hide-on-drawer-visible",
            attrs: { flat: "", icon: "menu" },
            on: {
              click: function($event) {
                return _vm.openLeftDrawer()
              }
            }
          })
        : _vm._e(),
      _vm.getAppMode_ICOR
        ? _c("q-toolbar-title", [
            _vm._v("\n    ICOR Manager\n    "),
            _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v(
                _vm._s(_vm.getUser_User.firstname) +
                  " " +
                  _vm._s(_vm.getUser_User.lastname) +
                  " (" +
                  _vm._s(_vm.getUser_User.username) +
                  ")"
              )
            ])
          ])
        : _vm._e(),
      _vm.getAppMode_ICOR && _vm.getShowPane_ICOR
        ? _c(
            "q-btn",
            {
              attrs: { flat: "", icon: "keyboard_backspace" },
              on: {
                click: function($event) {
                  return _vm.clickLastVisitHistory()
                }
              }
            },
            [
              _c(
                "q-tooltip",
                { attrs: { anchor: "center right", self: "center left" } },
                [_vm._v("ostatnia wyszukiwarka")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.getAppMode_ICOR && _vm.getShowPane_ICOR
        ? _c(
            "q-btn",
            {
              attrs: { flat: "", icon: "refresh" },
              on: {
                click: function($event) {
                  return _vm.refresheActiveTab()
                }
              }
            },
            [
              _c(
                "q-tooltip",
                { attrs: { anchor: "center right", self: "center left" } },
                [_vm._v("odśwież aktywną zakładkę")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.getAppMode_ICOR && _vm.getShowPane_ICOR
        ? _c(
            "q-btn",
            {
              attrs: { flat: "", icon: "add" },
              on: {
                click: function($event) {
                  return _vm.addNewTab()
                }
              }
            },
            [
              _c(
                "q-tooltip",
                { attrs: { anchor: "center right", self: "center left" } },
                [_vm._v("dodaj nową zakładkę")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.getAppMode_ICOR && _vm.getShowPane_ICOR
        ? _c(
            "q-btn",
            {
              attrs: { flat: "", icon: "close" },
              on: {
                click: function($event) {
                  return _vm.closeActiveTab()
                }
              }
            },
            [
              _c(
                "q-tooltip",
                { attrs: { anchor: "center right", self: "center left" } },
                [_vm._v("zamknij aktywną zakładkę")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "q-btn",
        { attrs: { flat: "", icon: "account_circle" } },
        [
          _c(
            "q-popover",
            [
              _c(
                "q-list",
                [
                  _vm.isLoggedIn
                    ? _c(
                        "q-item",
                        [
                          _c("q-item-side", {
                            attrs: { icon: "person", color: "primary" }
                          }),
                          _c(
                            "q-item-main",
                            [
                              _c("q-item-tile", { attrs: { label: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getUser_User.firstname +
                                      " " +
                                      _vm.getUser_User.lastname
                                  )
                                )
                              ]),
                              _c("q-item-tile", { attrs: { sublabel: "" } }, [
                                _vm._v(_vm._s(_vm.getUser_User.username))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn && _vm.getUser_User.phone
                    ? _c(
                        "q-item",
                        [
                          _c("q-item-side", {
                            attrs: { icon: "phone", color: "primary" }
                          }),
                          _c(
                            "q-item-main",
                            [
                              _c("q-item-tile", { attrs: { label: "" } }, [
                                _vm._v(_vm._s(_vm.getUser_User.phone))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn && _vm.getUser_User.email
                    ? _c(
                        "q-item",
                        [
                          _c("q-item-side", {
                            attrs: { icon: "mail", color: "primary" }
                          }),
                          _c(
                            "q-item-main",
                            [
                              _c("q-item-tile", { attrs: { label: "" } }, [
                                _vm._v(_vm._s(_vm.getUser_User.email))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn
                    ? _c(
                        "q-item",
                        {
                          directives: [
                            {
                              name: "close-overlay",
                              rawName: "v-close-overlay"
                            }
                          ],
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.doSettings($event)
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "settings" } }),
                          _c("q-item-main", { attrs: { label: "ustawienia" } })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn
                    ? _c(
                        "q-item",
                        {
                          directives: [
                            {
                              name: "close-overlay",
                              rawName: "v-close-overlay"
                            }
                          ],
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.doUserSettings($event)
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "account_box" } }),
                          _c("q-item-main", { attrs: { label: "moje konto" } })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn
                    ? _c(
                        "q-item",
                        {
                          directives: [
                            {
                              name: "close-overlay",
                              rawName: "v-close-overlay"
                            }
                          ],
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.doLogout($event)
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "exit_to_app" } }),
                          _c("q-item-main", { attrs: { label: "wyloguj" } })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.getAppMode_ICOR
        ? _c("q-btn", {
            attrs: { flat: "", icon: "menu" },
            on: {
              click: function($event) {
                return _vm.openRightDrawer()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }