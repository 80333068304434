var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "keep-alive",
        [
          _c(
            "q-tabs",
            {
              attrs: {
                animated: "",
                swipeable: "",
                "two-lines": "",
                inverted: "",
                "no-pane-border": "",
                align: "left"
              },
              on: { select: _vm.tabsChange },
              model: {
                value: _vm.tabs.selected,
                callback: function($$v) {
                  _vm.$set(_vm.tabs, "selected", $$v)
                },
                expression: "tabs.selected"
              }
            },
            [
              _vm._l(_vm.tabs.items, function(item, index) {
                return _c(
                  "q-tab",
                  {
                    key: item.name,
                    attrs: {
                      slot: "title",
                      name: item.name,
                      label:
                        (item.actionLabel ? item.actionLabel + " - " : "") +
                        item.label
                          ? item.label
                          : "Dane",
                      hidden: item.hidden,
                      default: index === 0
                    },
                    slot: "title"
                  },
                  [
                    item.label
                      ? _c("q-tooltip", [
                          _vm._v(_vm._s(item.label)),
                          item.sublabel !== ""
                            ? _c("div", [
                                _c("br"),
                                item.actionLabel
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.actionLabel) + " - ")
                                    ])
                                  : _vm._e(),
                                _vm._v(_vm._s(item.sublabel))
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "q-tab",
                {
                  attrs: {
                    slot: "title",
                    name: "tab-b-2",
                    hidden: _vm.tabHiddenAttachments,
                    icon: "attach_file"
                  },
                  slot: "title"
                },
                [_c("q-tooltip", [_vm._v("Załączniki")])],
                1
              ),
              _c(
                "q-tab",
                {
                  attrs: {
                    slot: "title",
                    name: "tab-b-3",
                    hidden: _vm.tabHiddenMultimedia,
                    icon: "local_movies"
                  },
                  slot: "title"
                },
                [_c("q-tooltip", [_vm._v("Multimedia")])],
                1
              ),
              _c(
                "q-tab",
                {
                  attrs: {
                    slot: "title",
                    name: "tab-b-4",
                    hidden: _vm.tabHiddenGeo,
                    icon: "place"
                  },
                  slot: "title"
                },
                [_c("q-tooltip", [_vm._v("Geolokalizacja")])],
                1
              ),
              _c(
                "q-tab",
                {
                  attrs: {
                    slot: "title",
                    name: "tab-b-6",
                    hidden: _vm.tabHiddenCalendar,
                    icon: "date_range"
                  },
                  slot: "title"
                },
                [_c("q-tooltip", [_vm._v("Kalendarium")])],
                1
              ),
              _c(
                "q-tab",
                {
                  attrs: {
                    slot: "title",
                    name: "tab-b-10",
                    hidden: _vm.tabHiddenAbstract,
                    icon: "bookmark_border"
                  },
                  slot: "title"
                },
                [_c("q-tooltip", [_vm._v("Abstrakt")])],
                1
              ),
              _c(
                "q-tab",
                {
                  attrs: {
                    slot: "title",
                    name: "tab-b-11",
                    hidden: _vm.tabHiddenCategories,
                    icon: "list"
                  },
                  slot: "title"
                },
                [_c("q-tooltip", [_vm._v("Kategorie")])],
                1
              ),
              _c(
                "q-tab",
                {
                  attrs: {
                    slot: "title",
                    name: "tab-b-7",
                    hidden: _vm.tabHiddenVersions,
                    icon: "done_all"
                  },
                  slot: "title"
                },
                [_c("q-tooltip", [_vm._v("Poprzednie wersje")])],
                1
              ),
              _c(
                "q-tab",
                {
                  attrs: {
                    slot: "title",
                    name: "tab-b-8",
                    hidden: _vm.tabHiddenAccess,
                    icon: "visibility"
                  },
                  slot: "title"
                },
                [_c("q-tooltip", [_vm._v("Historia dostępu")])],
                1
              ),
              _c(
                "q-tab",
                {
                  attrs: {
                    slot: "title",
                    name: "tab-b-9",
                    hidden: _vm.tabHiddenOptions,
                    icon: "widgets"
                  },
                  slot: "title"
                },
                [_c("q-tooltip", [_vm._v("Opcje")])],
                1
              ),
              _c(
                "q-tab",
                {
                  attrs: {
                    slot: "title",
                    name: "tab-b-5",
                    hidden: _vm.tabHiddenInfo,
                    icon: "info_outline"
                  },
                  slot: "title"
                },
                [_c("q-tooltip", [_vm._v("Informacje")])],
                1
              ),
              _vm._l(_vm.tabs.items, function(item, index) {
                return _c(
                  "q-tab-pane",
                  {
                    key: item.name,
                    ref: "mypanes",
                    refInFor: true,
                    staticClass: "q-pa-none",
                    attrs: { name: item.name, "keep-alive": "" }
                  },
                  [
                    _c("card-i-frame", {
                      staticClass: "q-pa-none",
                      attrs: {
                        url: item.frameurl,
                        tabitem: item,
                        ownertab: _vm.tabitem.name,
                        index: index
                      }
                    })
                  ],
                  1
                )
              }),
              _c(
                "q-tab-pane",
                {
                  staticClass: "q-pa-none",
                  attrs: { name: "tab-b-2", "keep-alive": "" }
                },
                [
                  _c("card-attachments", {
                    staticClass: "q-pa-none",
                    attrs: {
                      tabname: "tab-b-2",
                      tabitem: this.tabitem,
                      dbitem: this.dbitem
                    }
                  })
                ],
                1
              ),
              _c(
                "q-tab-pane",
                {
                  staticClass: "q-pa-none",
                  attrs: { name: "tab-b-3", "keep-alive": "" }
                },
                [
                  _c("card-multimedia", {
                    staticClass: "q-pa-none",
                    attrs: {
                      tabname: "tab-b-3",
                      tabitem: this.tabitem,
                      dbitem: this.dbitem
                    }
                  })
                ],
                1
              ),
              _c(
                "q-tab-pane",
                {
                  staticClass: "q-pa-none",
                  attrs: { name: "tab-b-4", "keep-alive": "" }
                },
                [
                  _c("card-geo", {
                    staticClass: "q-pa-none",
                    attrs: {
                      tabname: "tab-b-4",
                      tabitem: this.tabitem,
                      dbitem: this.dbitem
                    }
                  })
                ],
                1
              ),
              _c(
                "q-tab-pane",
                {
                  staticClass: "q-pa-none",
                  attrs: { name: "tab-b-6", "keep-alive": "" }
                },
                [
                  _c("card-calendar", {
                    staticClass: "q-pa-none",
                    attrs: {
                      tabname: "tab-b-6",
                      tabitem: this.tabitem,
                      dbitem: this.dbitem
                    }
                  })
                ],
                1
              ),
              _c(
                "q-tab-pane",
                {
                  staticClass: "q-pa-none",
                  attrs: { name: "tab-b-10", "keep-alive": "" }
                },
                [
                  _c("card-abstract", {
                    staticClass: "q-pa-none",
                    attrs: {
                      tabname: "tab-b-10",
                      tabitem: this.tabitem,
                      dbitem: this.dbitem
                    }
                  })
                ],
                1
              ),
              _c(
                "q-tab-pane",
                {
                  staticClass: "q-pa-none",
                  attrs: { name: "tab-b-11", "keep-alive": "" }
                },
                [
                  _c("card-categories", {
                    staticClass: "q-pa-none",
                    attrs: {
                      tabname: "tab-b-11",
                      tabitem: this.tabitem,
                      dbitem: this.dbitem
                    }
                  })
                ],
                1
              ),
              _c(
                "q-tab-pane",
                {
                  staticClass: "q-pa-none",
                  attrs: { name: "tab-b-7", "keep-alive": "" }
                },
                [
                  _c("card-version-history", {
                    staticClass: "q-pa-none",
                    attrs: {
                      tabname: "tab-b-7",
                      tabitem: this.tabitem,
                      dbitem: _vm.dbitem
                    }
                  })
                ],
                1
              ),
              _c(
                "q-tab-pane",
                {
                  staticClass: "q-pa-none",
                  attrs: { name: "tab-b-8", "keep-alive": "" }
                },
                [
                  _c("card-access-history", {
                    staticClass: "q-pa-none",
                    attrs: {
                      tabname: "tab-b-8",
                      tabitem: this.tabitem,
                      dbitem: _vm.dbitem
                    }
                  })
                ],
                1
              ),
              _c(
                "q-tab-pane",
                {
                  staticClass: "q-pa-none",
                  attrs: { name: "tab-b-9", "keep-alive": "" }
                },
                [
                  _c("card-options", {
                    staticClass: "q-pa-none",
                    attrs: {
                      tabname: "tab-b-9",
                      tabitem: this.tabitem,
                      dbitem: this.dbitem
                    }
                  })
                ],
                1
              ),
              _c(
                "q-tab-pane",
                {
                  staticClass: "q-pa-none",
                  attrs: { name: "tab-b-5", "keep-alive": "" }
                },
                [
                  _c("card-info", {
                    staticClass: "q-pa-none",
                    attrs: {
                      tabname: "tab-b-5",
                      mode: _vm.tabs.mode,
                      tabitem: this.tabitem,
                      dbitem: _vm.dbitem,
                      jobitem: _vm.jobitem
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }