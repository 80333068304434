<template>
  <q-modal ref="thisModal" v-model="modalOpened" v-if="isLoggedIn">
    <q-list>
      <q-item>
        <q-item-side icon="person" color="primary" />
        <q-item-main>
          <q-field
            helper="wprowadź swoje imię"
            :error="$v.imie.$error"
            :error-label="`imię - ${errorMsg($v.imie)}`"
            >
            <q-input autofocus type="text"
              float-label="imię"
              v-model="imie"
              @blur="$v.imie.$touch"
            />
          </q-field>
        </q-item-main>
      </q-item>
      <q-item>
        <q-item-side icon="person" color="primary" />
        <q-item-main>
          <q-field
            helper="wprowadź swoje nazwisko"
            :error="$v.nazwisko.$error"
            :error-label="`nazwisko - ${errorMsg($v.nazwisko)}`"
            >
            <q-input type="text"
              float-label="nazwisko"
              v-model="nazwisko"
              @blur="$v.nazwisko.$touch"
            />
          </q-field>
        </q-item-main>
      </q-item>
      <q-item>
        <q-item-side icon="info" color="primary" />
        <q-item-main>
          <q-field
            helper="wprowadź informacje dodatkowe"
            :error="$v.opis.$error"
            :error-label="`opis - ${errorMsg($v.opis)}`"
            >
            <q-input type="text"
              float-label="informacje dodatkowe"
              v-model="opis"
              @blur="$v.opis.$touch"
            />
          </q-field>
        </q-item-main>
      </q-item>
      <q-item-separator inset />
      <q-item>
        <q-item-main>
          <q-btn-group rounded>
            <q-btn rounded icon="done" color="primary"
              @click="submitData" label="Zapamiętaj" :disabled="$v.$invalid"
            />
            <q-btn rounded icon="cancel" color="negative" @click="cancelData" label="Anuluj" />
          </q-btn-group>
        </q-item-main>
      </q-item>
    </q-list>
  </q-modal>
</template>

<script type="text/javascript">
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'UserDataChange',
  data () {
    return {
      modalOpened: false,
      imie: '',
      nazwisko: '',
      opis: ''
    }
  },
  validations: {
    imie: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(24)
    },
    nazwisko: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(24)
    },
    opis: {
      maxLength: maxLength(200)
    }
  },
  computed: {
    ...mapGetters([
      'getAuth_iSES_operator',
      'getAuth_iSES_admin',
      'getAuth_iSES_mpadmin',
      'getUser_User',
      'isLoggedIn'
    ])
  },
  methods: {
    errorMsg (item) {
      if (!item.$error) return ''
      if (item.required === false) return 'wymagane'
      if (item.minLength === false) return `minimalna liczba znaków: ${item.$params.minLength.min}`
      if (item.maxLength === false) return `maksymalna liczba znaków: ${item.$params.maxLength.max}`
    },
    modalShow (event) {
      let user = this.getUser_User
      this.imie = user.firstname
      this.nazwisko = user.lastname
      this.opis = user.description
      this.$v.$touch()
      this.modalOpened = true
    },
    async submitData () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$q.notify({message: 'Popraw dane przed zapamiętaniem.', type: 'negative', position: 'bottom-left'})
        return
      }
      let [status, info] = await this.$icor.main.setUserInfo(this.imie, this.nazwisko, this.opis)
      this.modalOpened = false
      if (status === 0) {
        this.$q.notify({message: 'Dane zostały zmienione', type: 'positive', position: 'bottom-right'})
      } else {
        this.$q.notify({message: info, type: 'negative', position: 'bottom-right'})
      }
    },
    cancelData () {
      this.modalOpened = false
    }
  }
}
</script>
<style scoped>
</style>
