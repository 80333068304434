var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-field",
    [
      _c("q-datetime", {
        ref: "ifMyField",
        attrs: {
          "float-label": _vm.label,
          value: _vm.value,
          type: "date",
          "cancel-label": "anuluj",
          "ok-label": "OK",
          "clear-label": "wyczyść",
          "month-names": [
            "styczeń",
            "luty",
            "marzec",
            "kwiecień",
            "maj",
            "czerwiec",
            "lipiec",
            "sierpień",
            "wrzesień",
            "październik",
            "listopad",
            "grudzień"
          ],
          "day-names": [
            "poniedziałek",
            "wtorek",
            "środa",
            "czwartek",
            "piątek",
            "sobota",
            "niedziela"
          ],
          "monday-first": "",
          format: "YYYY-MM-DD",
          format24h: ""
        },
        on: { input: _vm.updateValue }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }