var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoggedIn
    ? _c(
        "q-modal",
        {
          ref: "thisModal",
          model: {
            value: _vm.modalOpened,
            callback: function($$v) {
              _vm.modalOpened = $$v
            },
            expression: "modalOpened"
          }
        },
        [
          _c(
            "q-list",
            [
              _c(
                "q-item",
                [
                  _c("q-item-side", {
                    attrs: { icon: "email", color: "primary" }
                  }),
                  _c(
                    "q-item-main",
                    [
                      _c(
                        "q-field",
                        {
                          attrs: {
                            helper: "wprowadź nowy email",
                            error: _vm.$v.email.$error,
                            "error-label": "wymagany email"
                          }
                        },
                        [
                          _c("q-input", {
                            attrs: {
                              autofocus: "",
                              type: "email",
                              "float-label": "nowy email"
                            },
                            on: { blur: _vm.$v.email.$touch },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item-tile",
                        { attrs: { sublabel: "", lines: "3" } },
                        [
                          _vm._v(
                            "\n          Po zapamiętaniu, na podany wyżej email zostanie wysłany link aktywacyjny.\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("q-item-separator", { attrs: { inset: "" } }),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-main",
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { rounded: "" } },
                        [
                          _c("q-btn", {
                            attrs: {
                              rounded: "",
                              icon: "done",
                              color: "primary",
                              label: "Zapamiętaj",
                              disabled: _vm.$v.$invalid
                            },
                            on: { click: _vm.submitData }
                          }),
                          _c("q-btn", {
                            attrs: {
                              rounded: "",
                              icon: "cancel",
                              color: "negative",
                              label: "Anuluj"
                            },
                            on: { click: _vm.cancelData }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }