var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-toolbar",
        { attrs: { inverted: "" } },
        [
          _c("q-toolbar-title", [
            _vm._v("\n        Opcje i operacje\n        "),
            _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v(
                "\n          dodatkowe opcje i pozostałe czynności\n        "
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }