var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-toolbar",
        { attrs: { inverted: "" } },
        [
          _c("q-toolbar-title", [
            _vm._v("\n        Multimedia\n        "),
            _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v(
                "\n          zarządzanie plikami multimedialnymi\n        "
              )
            ])
          ]),
          _c(
            "q-btn",
            {
              attrs: { flat: "", dense: "", icon: "photo_library" },
              on: {
                click: function($event) {
                  return _vm.showGallery()
                }
              }
            },
            [_c("q-tooltip", [_vm._v("Wyświetl galerię")])],
            1
          ),
          _c(
            "q-btn",
            {
              attrs: { flat: "", dense: "", icon: "refresh" },
              on: {
                click: function($event) {
                  return _vm.dataLoad()
                }
              }
            },
            [_c("q-tooltip", [_vm._v("Odśwież listę multimediów")])],
            1
          )
        ],
        1
      ),
      _c("br"),
      _c("q-uploader", {
        ref: "uploader",
        attrs: {
          multiple: "",
          url: _vm.getMultimediaAddURL,
          additionalFields: _vm.uploaderFields,
          "auto-expand": "",
          "stack-label": "Dodaj nowe pliki multimedialne"
        },
        on: { finish: _vm.finishUpload }
      }),
      _c("br"),
      _c("br"),
      _c(
        "q-list",
        {
          directives: [
            {
              name: "dragula",
              rawName: "v-dragula",
              value: _vm.dataItems,
              expression: "dataItems"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.itemsExists,
              expression: "itemsExists"
            }
          ],
          ref: "files",
          attrs: {
            separator: "",
            multiline: "",
            dense: "",
            bag: "multimedia-bag"
          }
        },
        _vm._l(_vm.dataItems, function(item, index) {
          return _c(
            "q-item",
            { key: item._OID, class: index },
            [
              _c("q-item-side", [
                _c("img", {
                  staticClass: "q-item-image handle cursor-pointer",
                  attrs: { src: _vm.getItemThumbURL(item) }
                })
              ]),
              _c(
                "q-item-main",
                [
                  _c(
                    "q-collapsible",
                    {
                      attrs: {
                        label: item.FileName + item.FileExt,
                        sublabel:
                          _vm.humanStorageSize(item.FileSize) +
                          " - " +
                          item._datetime +
                          " - " +
                          item._UserName
                      }
                    },
                    [
                      _c("i-q-field", {
                        attrs: { label: "Opis", iscopy: "", ispaste: "" },
                        model: {
                          value: item.Description,
                          callback: function($$v) {
                            _vm.$set(item, "Description", $$v)
                          },
                          expression: "item.Description"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Informacja Podmiot Udostepniający",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: {
                          search: _vm.searchInformacjaPodmiotUdostepniajacy
                        },
                        model: {
                          value: item.InformacjaPodmiotUdostepniajacy,
                          callback: function($$v) {
                            _vm.$set(
                              item,
                              "InformacjaPodmiotUdostepniajacy",
                              $$v
                            )
                          },
                          expression: "item.InformacjaPodmiotUdostepniajacy"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Informacja Osoba Odpowiedzialna",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchInformacjaOsobaOdpowiedzialna },
                        model: {
                          value: item.InformacjaOsobaOdpowiedzialna,
                          callback: function($$v) {
                            _vm.$set(item, "InformacjaOsobaOdpowiedzialna", $$v)
                          },
                          expression: "item.InformacjaOsobaOdpowiedzialna"
                        }
                      }),
                      _c("i-q-field-date", {
                        attrs: { label: "Informacja Data Wytworzenia" },
                        model: {
                          value: item.InformacjaDataWytworzenia,
                          callback: function($$v) {
                            _vm.$set(item, "InformacjaDataWytworzenia", $$v)
                          },
                          expression: "item.InformacjaDataWytworzenia"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Informacja Opis Czynności",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchInformacjaOpisCzynnosci },
                        model: {
                          value: item.InformacjaOpisCzynnosci,
                          callback: function($$v) {
                            _vm.$set(item, "InformacjaOpisCzynnosci", $$v)
                          },
                          expression: "item.InformacjaOpisCzynnosci"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Kategoria",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchCategory },
                        model: {
                          value: item.Category,
                          callback: function($$v) {
                            _vm.$set(item, "Category", $$v)
                          },
                          expression: "item.Category"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Rodzaj",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchKind },
                        model: {
                          value: item.Kind,
                          callback: function($$v) {
                            _vm.$set(item, "Kind", $$v)
                          },
                          expression: "item.Kind"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Grupa",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchGroup },
                        model: {
                          value: item.ItemGroup,
                          callback: function($$v) {
                            _vm.$set(item, "ItemGroup", $$v)
                          },
                          expression: "item.ItemGroup"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Klucz",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchItemKey },
                        model: {
                          value: item.ItemKey,
                          callback: function($$v) {
                            _vm.$set(item, "ItemKey", $$v)
                          },
                          expression: "item.ItemKey"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Rel",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchItemRel },
                        model: {
                          value: item.ItemRel,
                          callback: function($$v) {
                            _vm.$set(item, "ItemRel", $$v)
                          },
                          expression: "item.ItemRel"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Tytuł",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchItemTitle },
                        model: {
                          value: item.ItemTitle,
                          callback: function($$v) {
                            _vm.$set(item, "ItemTitle", $$v)
                          },
                          expression: "item.ItemTitle"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Typ",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchItemType },
                        model: {
                          value: item.ItemType,
                          callback: function($$v) {
                            _vm.$set(item, "ItemType", $$v)
                          },
                          expression: "item.ItemType"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Status",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchItemStatus },
                        model: {
                          value: item.ItemStatus,
                          callback: function($$v) {
                            _vm.$set(item, "ItemStatus", $$v)
                          },
                          expression: "item.ItemStatus"
                        }
                      }),
                      _c("br"),
                      _c(
                        "q-btn",
                        {
                          attrs: { icon: "save", color: "primary", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.multimediaSave(item._OID)
                            }
                          }
                        },
                        [_vm._v("Zapamiętaj")]
                      ),
                      _c(
                        "q-btn",
                        {
                          ref: "target",
                          refInFor: true,
                          attrs: {
                            icon: "file_download",
                            "icon-right": "expand_more",
                            color: "primary",
                            small: ""
                          }
                        },
                        [
                          _vm._v("Pobierz\n              "),
                          _c(
                            "q-popover",
                            {
                              ref: "popoverDownload",
                              refInFor: true,
                              attrs: { fit: "" }
                            },
                            [
                              _c(
                                "q-list",
                                { attrs: { separator: "", link: "" } },
                                [
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        {
                                          name: "close-overlay",
                                          rawName: "v-close-overlay"
                                        }
                                      ],
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.downloadMultimedia(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("q-item-main", [_vm._v("Multimedium")])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        {
                                          name: "close-overlay",
                                          rawName: "v-close-overlay"
                                        }
                                      ],
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.downloadMultimediaThumbnail(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [_c("q-item-main", [_vm._v("Miniaturę")])],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        {
                                          name: "close-overlay",
                                          rawName: "v-close-overlay"
                                        }
                                      ],
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.downloadMultimediaOriginal(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [_c("q-item-main", [_vm._v("Oryginał")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      item.Status === "Z1"
                        ? _c(
                            "q-btn",
                            {
                              ref: "target",
                              refInFor: true,
                              attrs: {
                                icon: "content_copy",
                                color: "primary",
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  ;(_vm.selectedItem = item),
                                    (_vm.modalURLClipboard = true)
                                }
                              }
                            },
                            [_vm._v("Skopiuj URL")]
                          )
                        : _vm._e(),
                      _c(
                        "q-btn",
                        {
                          attrs: {
                            icon: "delete",
                            "icon-right": "expand_more",
                            color: "negative",
                            small: "",
                            outline: ""
                          }
                        },
                        [
                          _vm._v("Usuń\n              "),
                          _c(
                            "q-popover",
                            {
                              ref: "popoverRemoveMultimedia",
                              refInFor: true,
                              attrs: { fit: "" }
                            },
                            [
                              _c(
                                "q-list",
                                { attrs: { separator: "", link: "" } },
                                [
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        {
                                          name: "close-overlay",
                                          rawName: "v-close-overlay"
                                        }
                                      ],
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.removeMultimedia(item._OID)
                                        }
                                      }
                                    },
                                    [
                                      _c("q-item-main", [_vm._v("Multimedium")])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        {
                                          name: "close-overlay",
                                          rawName: "v-close-overlay"
                                        }
                                      ],
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.removeMultimediaThumbnail(
                                            item._OID
                                          )
                                        }
                                      }
                                    },
                                    [_c("q-item-main", [_vm._v("Miniatury")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-item-side",
                { attrs: { right: "" } },
                [
                  item.Status == ""
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "warning" } }, [
                            _vm._v("N1")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Status == "N1"
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "warning" } }, [
                            _vm._v(_vm._s(item.Status))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Status == "P1"
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "info" } }, [
                            _vm._v(_vm._s(item.Status))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Status == "B1"
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "negative" } }, [
                            _vm._v(_vm._s(item.Status))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Status == "Z1"
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "positive" } }, [
                            _vm._v(_vm._s(item.Status))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Status == "U1"
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "faded" } }, [
                            _vm._v(_vm._s(item.Status))
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c("br"),
      _c("br"),
      _c(
        "viewer",
        {
          ref: "viewer",
          staticClass: "viewer",
          attrs: { options: _vm.options, images: _vm.dataItems },
          on: { inited: _vm.inited }
        },
        _vm._l(_vm.dataItems, function(item) {
          return _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            key: item._OID,
            attrs: {
              src: _vm.getItemThumbURL(item),
              "data-source": _vm.getItemImageURL(item),
              alt: item.Description
            }
          })
        }),
        0
      ),
      _c(
        "q-modal",
        {
          attrs: { "content-css": { padding: "20px", minWidth: "90vw" } },
          model: {
            value: _vm.modalURLClipboard,
            callback: function($$v) {
              _vm.modalURLClipboard = $$v
            },
            expression: "modalURLClipboard"
          }
        },
        [
          _c("i-q-field", {
            attrs: {
              label: "Link relatywny do multimedium",
              value: _vm.getItemImageWWWUrl(false),
              iscopy: ""
            }
          }),
          _c("i-q-field", {
            attrs: {
              label: "Link absolutny do multimedium",
              value: _vm.getItemImageWWWUrl(true),
              iscopy: ""
            }
          }),
          _c("i-q-field", {
            attrs: {
              label: "Link relatywny do miniatury",
              value: _vm.getItemThumbWWWUrl(false),
              iscopy: ""
            }
          }),
          _c("i-q-field", {
            attrs: {
              label: "Link absolutny do miniatury",
              value: _vm.getItemThumbWWWUrl(true),
              iscopy: ""
            }
          }),
          _c("i-q-field", {
            attrs: {
              label: "Link relatywny do oryginału",
              value: _vm.getItemWWWUrl(false),
              iscopy: ""
            }
          }),
          _c("i-q-field", {
            attrs: {
              label: "Link absolutny do oryginału",
              value: _vm.getItemWWWUrl(true),
              iscopy: ""
            }
          }),
          _c("br"),
          _c("q-btn", {
            staticClass: "float-right",
            attrs: { color: "primary", label: "Zamknij" },
            on: {
              click: function($event) {
                _vm.modalURLClipboard = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }