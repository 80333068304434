import { render, staticRenderFns } from "./toolbar.vue?vue&type=template&id=588def28&scoped=true&"
import script from "./toolbar.vue?vue&type=script&lang=js&"
export * from "./toolbar.vue?vue&type=script&lang=js&"
import style0 from "./toolbar.vue?vue&type=style&index=0&id=588def28&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588def28",
  null
  
)

component.options.__file = "toolbar.vue"
export default component.exports