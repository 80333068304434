<template>
  <div>
    <q-list v-if="isLoggedIn">
      <!-- czesc informacyjna -->
      <q-item multiline>
        <q-item-side icon="person" color="primary" />
        <q-item-main inset>
          <q-item-tile label>{{getUser_User.firstname}} {{getUser_User.lastname}}<br></q-item-tile>
          <q-item-tile sublabel>Imię i nazwisko</q-item-tile>
        </q-item-main>
      </q-item>
      <q-item>
        <q-item-side icon="perm_identity" color="primary" />
        <q-item-main>
          <q-item-tile label>{{getUser_User.username}}</q-item-tile>
          <q-item-tile sublabel>login</q-item-tile>
        </q-item-main>
      </q-item>
      <q-item>
        <q-item-side icon="phone" color="primary" />
        <q-item-main>
          <q-item-tile label>{{getUser_User.phone}}</q-item-tile>
          <q-item-tile sublabel>telefon</q-item-tile>
        </q-item-main>
      </q-item>
      <q-item>
        <q-item-side icon="mail" color="primary" />
        <q-item-main>
          <q-item-tile label>{{getUser_User.email}}</q-item-tile>
          <q-item-tile sublabel>email</q-item-tile>
        </q-item-main>
      </q-item>
      <q-item>
        <q-item-side icon="info" color="primary" />
        <q-item-main>
          <q-item-tile label>{{getUser_User.description}}</q-item-tile>
          <q-item-tile sublabel>informacje dodatkowe</q-item-tile>
        </q-item-main>
      </q-item>
      <q-item-separator inset />
      <q-item>
        <q-item-main>
            <q-btn-group rounded>
              <q-btn icon="edit" color="primary" rounded @click="editData" label="Edycja" />
              <q-btn icon="mail" color="primary" rounded @click="changeEmail" label="Zmiana email" />
              <q-btn icon="phone" color="primary" rounded @click="changePhone" label="Zmiana telefonu" />
              <q-btn icon="security" color="primary" rounded @click="changePassword" label="Zmiana hasła" />
            </q-btn-group>
        </q-item-main>
      </q-item>
    </q-list>
    <userDataChange ref="thisUserDataChange"></userDataChange>
    <userEmailChangeSimple ref="thisUserEmailChangeSimple"></userEmailChangeSimple>
    <userPhoneChange ref="thisUserPhoneChange"></userPhoneChange>
    <userPasswordChange ref="thisUserPasswordChange" mode="user"></userPasswordChange>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import IQFieldEditor from 'components/fields/iqFieldEditor'
import IQFieldSelect from 'components/fields/iqFieldSelect'
import userDataChange from 'components/user/userDataChange'
import userEmailChangeSimple from 'components/user/userEmailChangeSimple'
import userPasswordChange from 'components/user/userPasswordChange'
import userPhoneChange from 'components/user/userPhoneChange'

export default {
  name: 'UstawieniaUzytkownikDane',
  components: {
    userDataChange,
    userEmailChangeSimple,
    userPasswordChange,
    userPhoneChange,
    IQFieldEditor,
    IQFieldSelect
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'getUser_User',
      'isLoggedIn'
    ])
  },
  methods: {
    editData () {
      this.$refs.thisUserDataChange.modalShow()
    },
    changeEmail () {
      this.$refs.thisUserEmailChangeSimple.modalShow()
    },
    changePhone () {
      this.$refs.thisUserPhoneChange.modalShow()
    },
    changePassword () {
      this.$refs.thisUserPasswordChange.modalShow('')
    }
  }
}
</script>

<style scoped>
</style>
