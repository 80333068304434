var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-toolbar",
        { attrs: { inverted: "" } },
        [
          _c("q-toolbar-title", [
            _vm._v("\n      Historia dostępu do rekordu\n      "),
            _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v("\n        lista użytkowników i daty\n      ")
            ])
          ])
        ],
        1
      ),
      _c("q-table", {
        attrs: {
          title: "",
          data: _vm.dataItems,
          columns: _vm.columns,
          "row-key": "_OID",
          pagination: _vm.pagination,
          "rows-per-page-options": [5, 10, 20, 50, 100, 0],
          dense: "",
          separator: "cell",
          "no-data-label": "brak danych",
          "no-results-label": "brak wyników",
          "loading-label": "ładuję dane...",
          "rows-per-page-label": "rekordów na stronę",
          "selected-rows-label": function(rowsNumber) {
            return "zaznaczono " + rowsNumber + " pozycji"
          },
          "pagination-label": function(start, end, total) {
            return start + "-" + end + " z " + total
          }
        },
        on: {
          "update:pagination": function($event) {
            _vm.pagination = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }