import { render, staticRenderFns } from "./cardMultimedia.vue?vue&type=template&id=7ddc0bf7&scoped=true&"
import script from "./cardMultimedia.vue?vue&type=script&lang=js&"
export * from "./cardMultimedia.vue?vue&type=script&lang=js&"
import style0 from "./cardMultimedia.vue?vue&type=style&index=0&id=7ddc0bf7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ddc0bf7",
  null
  
)

component.options.__file = "cardMultimedia.vue"
export default component.exports