<template>
  <div>
    <q-toolbar inverted>
      <q-toolbar-title>
        Kategorie
        <span slot="subtitle">
          wybór przypisanych do materiału kategorii
        </span>
      </q-toolbar-title>
    </q-toolbar>
    <!--
      <i-q-field label="Tytuł" v-model="kalendarium.nazwa"></i-q-field>
      <i-q-field-date label="Data rozpoczęcia" v-model="kalendarium.dataRozpoczęcia"></i-q-field-date>
      <i-q-field-date label="Data Zakończenia" v-model="kalendarium.dataZakonczenia"></i-q-field-date>
      <i-q-field-date label="Wyświetlaj aktualności od" v-model="kalendarium.wyswietlajAktualnosciOd"></i-q-field-date>
      <i-q-field-date label="Wyświetlaj aktualności do" v-model="kalendarium.wyswietlajAktualnosciDo"></i-q-field-date>
      <i-q-field-editor label="Opis" v-model="kalendarium.opis"></i-q-field-editor>
      <i-q-field-text-area label="Uwagi" v-model="kalendarium.uwagi"></i-q-field-text-area>
      <i-q-field-select label="Priorytet wyświetlania" v-model="kalendarium.priorytetWyswietlania" :options="optionsPriorytetWyswietlania"></i-q-field-select>
      <i-q-field-select label="Priorytet aktualności" v-model="kalendarium.priorytetAktualnosci" :options="optionsPriorytetAktualnosci"></i-q-field-select>
      <i-q-field-select label="Status zatwierdzenia" v-model="kalendarium.statusZatwierdzenia" :options="optionsStatusZatwierdzenia"></i-q-field-select>
      <br>
      <q-btn icon="save" color="primary" small @click="dataSave">&nbsp;Zapamiętaj</q-btn>
    -->

    <q-list v-show="dataItems.length>0" v-for="kategoria in thisCategories" :key="kategoria">
      <q-list-header>{{kategoria}}</q-list-header>
      <q-item v-for="item in thisGroups(kategoria)" :key="item._oid">
        <q-item-side>
          <q-toggle v-model="item.kchecked" :true-value="1" :false-value="0" @input="categoryChange(item)"/>
        </q-item-side>
        <q-item-main :label="item.grupa" :sublabel="item.rola">
        <!-- {{item.kategoria}} {{item.rola}} {{item.grupa}} {{item.koid}} -->
        </q-item-main>
      </q-item>
    </q-list>

  </div>
</template>

<script type="text/javascript">
import IQField from 'components/fields/iqField'
import IQFieldDate from 'components/fields/iqFieldDate'
import IQFieldEditor from 'components/fields/iqFieldEditor'
import IQFieldTextArea from 'components/fields/iqFieldTextArea'
import IQFieldSelect from 'components/fields/iqFieldSelect'

export default {
  name: 'CardCategories',
  props: {
    tabname: {
      type: String
    },
    tabitem: {
      type: Object
    },
    dbitem: {
      type: Object
    }
  },
  data () {
    return {
      categories: {
        nazwa: ''
      },
      dataLoaded: false,
      dataItems: [],
      dataItemsByOID: {}
    }
  },
  components: {
    IQField,
    IQFieldDate,
    IQFieldEditor,
    IQFieldTextArea,
    IQFieldSelect
  },
  created () {
    this.$root.$on('change-ICOR-tabs', this.changeICORTabs)
  },
  beforeDestroy () {
    this.$root.$off('change-ICOR-tabs', this.changeICORTabs)
  },
  computed: {
    thisCategories () {
      /*
      grupa"grupa 1"
      kategoria"kategoria 1"
      koid"509C7CD41B9A4D81855F13D4839B3B13"
      rola"rola 1"
      _oid"F11815C61A8842E38FAF9D6306DBA6C1"
      */
      let d = {}
      for (var i = 0; i < this.dataItems.length; i++) {
        d[this.dataItems[i].kategoria] = 1
      }
      let l = Object.keys(d).sort()
      return l
    }
  },
  methods: {
    async dataLoad () {
      this.dataItemsByOID = {}
      this.dataItems = await this.$icor.main.categoriesLoad(this.dbitem)
      this.dataLoaded = true
    },
    dataSave () {
      console.log('categories save')
    },
    thisGroups (kategoria) {
      let l = []
      for (var i = 0; i < this.dataItems.length; i++) {
        if (this.dataItems[i].kategoria === kategoria) {
          l.push(this.dataItems[i])
        }
      }
      return l
    },
    async categoryChange (item) {
      let response = await this.$icor.main.categoriesSave(this.dbitem, item)
      if (response.data.errors) {
        this.$q.notify({type: 'negative', position: 'bottom-right', message: 'Wystąpił błąd podczas zapamiętywania kategorii: ' + response.data.errors[0].info})
      }
    },
    changeICORTabs (event) {
      if (event.tabitem.id !== this.tabitem.id) {
        return
      }
      if (event.tabname === this.tabname) {
        if (this.dataLoaded) {
          return
        }
        if ((this.dbitem.oid === '') || (this.dbitem.tid === '') || (this.dbitem.poid === '')) {
          return
        }
        this.dataLoad()
      }
    }
  },
  watch: {
    'dbitem.oid': {
      handler: function (vnew, vold) {
        this.dataItems = []
        this.dataItemsByOID = {}
        this.dataLoaded = false
      },
      deep: true
    }
  }
}
</script>

<style scoped>
</style>
