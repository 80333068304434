<template>
  <q-modal ref="thisModal" v-model="modalOpened" v-if="isLoggedIn">
    <q-list>
      <q-item v-if="mode==='user'">
        <q-item-side icon="lock_open" color="primary" />
        <q-item-main>
          <q-field
            helper="wprowadź aktualne hasło"
            :error="$v.oldpassword.$error"
            error-label="wymagane aktualne hasło"
            >
            <q-input autofocus type="password"
              float-label="aktualne hasło"
              v-model="oldpassword"
              @blur="$v.oldpassword.$touch"
            />
          </q-field>
        </q-item-main>
      </q-item>
      <q-item>
        <q-item-side icon="lock_outline" color="primary" />
        <q-item-main>
          <q-field
            helper="wprowadź nowe hasło"
            :error="$v.password.$error"
            error-label="wymagane nowe hasło"
            >
            <q-input type="password"
              float-label="nowe hasło"
              v-model="password"
              @blur="$v.password.$touch"
            />
          </q-field>
        </q-item-main>
      </q-item>
      <q-item>
        <q-item-side icon="lock_outline" color="primary" />
        <q-item-main>
          <q-field
            helper="wprowadź ponownie nowe hasło"
            :error="$v.password2.$error"
            error-label="wymagane powtórzenie nowego hasła"
            >
            <q-input type="password"
              float-label="powtórz nowe hasło"
              v-model="password2"
              @blur="$v.password2.$touch"
            />
          </q-field>
        </q-item-main>
      </q-item>
      <q-item-separator inset />
      <q-item>
        <q-item-main>
          <q-btn-group rounded>
            <q-btn rounded icon="done" color="primary"
              @click="submitData" label="Zapamiętaj" :disabled="$v.$invalid"
            />
            <q-btn rounded icon="cancel" color="negative" @click="cancelData" label="Anuluj "/>
          </q-btn-group>
        </q-item-main>
      </q-item>
    </q-list>
  </q-modal>
</template>

<script type="text/javascript">
import { required, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'UserPasswordChange',
  props: [
    'mode' // user, operator, admin
  ],
  data () {
    return {
      modalOpened: false,
      action: '',
      user: {},
      oldpassword: '',
      password: '',
      password2: ''
    }
  },
  validations () {
    let dv = {
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(40)
      },
      password2: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs('password')
      }
    }
    if (this.mode === 'user') {
      dv = {
        ...dv,
        oldpassword: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(40)
        }
      }
    }
    return dv
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    modalShow (action, user) {
      this.action = action
      this.user = user
      this.oldpassword = ''
      this.password = ''
      this.password2 = ''
      this.modalOpened = true
      this.$v.$touch()
    },
    submitData () {
      this.modalOpened = false
    },
    cancelData () {
      this.modalOpened = false
    }
  }
}
</script>
<style scoped>
</style>
