<template>
  <q-layout ref="layout" view="lhr LpR lfr">
    <q-layout-header>
      <toolbar></toolbar>
    </q-layout-header>
    <drawer ref="leftDrawer"></drawer>
    <drawer-right ref="rightDrawer"></drawer-right>
    <q-page-container v-show="getShowPane_ICOR">
      <!--
      <keep-alive>
        <router-view name="icor"/>
      </keep-alive>
      -->
      <manager></manager>
    </q-page-container>
    <q-page-container v-show="getShowPane_Content">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { openURL } from 'quasar'
import { mapGetters } from 'vuex'
import toolbar from './components/toolbar.vue'
import drawer from './components/drawer.vue'
import drawerRight from './components/drawerRight.vue'
import manager from '../pages/icor/manager/manager.vue'

export default {
  name: 'LayoutDefault',
  data () {
    return {
      leftDrawerOpen: false
    }
  },
  components: {
    toolbar,
    drawer,
    drawerRight,
    manager
  },
  computed: {
    ...mapGetters(['getMobileMode', 'getShowPane_ICOR', 'getShowPane_Content', 'isLoggedIn'])
  },
  async mounted () {
    let isloggedin = await this.$icor.main.checkSession()
    if (!isloggedin) {
      this.$router.push('/login')
    } else {
      this.$root.$emit('default-init')
    }
  },
  updated () {
    if (!this.isLoggedIn) {
      this.$router.push('/login')
    }
  },
  methods: {
    openURL
  }
}
</script>

<style>
</style>
