var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-toolbar",
        { attrs: { inverted: "" } },
        [
          _c("q-toolbar-title", [
            _vm._v("\n        Zakładki i odnośniki\n        "),
            _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v(
                "\n          lista zakładek i odnośników do wykorzystania\n        "
              )
            ])
          ])
        ],
        1
      ),
      _vm.tabsExists
        ? _c(
            "q-list",
            {
              directives: [
                {
                  name: "dragula",
                  rawName: "v-dragula",
                  value: _vm.tabs.items,
                  expression: "tabs.items"
                }
              ],
              ref: "tabslist",
              attrs: { separator: "", multiline: "", bag: "tabs-bag" }
            },
            _vm._l(_vm.tabs.items, function(item) {
              return _c(
                "q-item",
                { key: item.id },
                [
                  _c(
                    "q-item-side",
                    [
                      _c(
                        "q-chip",
                        {
                          staticClass: "handle cursor-pointer",
                          attrs: { color: "primary" }
                        },
                        [
                          _c("q-icon", {
                            staticClass: "handle cursor-pointer",
                            attrs: { color: "bg-primary", name: item.icon }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-item-main",
                    [
                      _c("q-item-tile", {
                        staticClass: "cursor-pointer",
                        attrs: { label: "" },
                        domProps: { innerHTML: _vm._s(item.label) },
                        nativeOn: {
                          click: function($event) {
                            _vm.tabs.selected = item.name
                          }
                        }
                      }),
                      _c("q-item-tile", { attrs: { sublabel: "" } }, [
                        _vm._v(
                          _vm._s(item.actionlabel) + " " + _vm._s(item.sublabel)
                        )
                      ])
                    ],
                    1
                  ),
                  _c("q-item-side", {
                    staticClass: "cursor-pointer",
                    attrs: { right: "", icon: "close", color: "red" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.removeTab(item)
                      }
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }