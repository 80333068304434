var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout-drawer",
    {
      attrs: { side: "left", overlay: "" },
      model: {
        value: _vm.drawerOpen,
        callback: function($$v) {
          _vm.drawerOpen = $$v
        },
        expression: "drawerOpen"
      }
    },
    [
      _c(
        "q-scroll-area",
        { staticStyle: { width: "100%", height: "100%" } },
        [
          _vm.getAppMode_ICOR
            ? _c(
                "q-list",
                { attrs: { "no-border": "", "inset-separator": "" } },
                [
                  _vm.isLoggedIn
                    ? _c(
                        "q-collapsible",
                        {
                          attrs: {
                            icon: "person",
                            label:
                              _vm.getUser_User.firstname +
                              " " +
                              _vm.getUser_User.lastname,
                            sublabel: _vm.getUser_User.username
                          }
                        },
                        [
                          _vm.isLoggedIn && _vm.getUser_User.phone
                            ? _c(
                                "q-item",
                                [
                                  _c("q-item-side", {
                                    attrs: { icon: "phone", color: "primary" }
                                  }),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [_vm._v(_vm._s(_vm.getUser_User.phone))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isLoggedIn && _vm.getUser_User.email
                            ? _c(
                                "q-item",
                                [
                                  _c("q-item-side", {
                                    attrs: { icon: "mail", color: "primary" }
                                  }),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [_vm._v(_vm._s(_vm.getUser_User.email))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn
                    ? _c("q-item-separator", { attrs: { inset: "" } })
                    : _vm._e(),
                  _vm.isLoggedIn
                    ? _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.$router.push("/icor")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "device_hub" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Panel operatora",
                              sublabel: "wprowadzanie danych, zarządzanie"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn
                    ? _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.doFilesManager()
                            }
                          }
                        },
                        [
                          _c("q-item-side", {
                            attrs: { icon: "folder_shared" }
                          }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Zasoby plikowe",
                              sublabel: "pliki w serwisach, pliki użytkownika"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoggedIn
                    ? _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.$router.push("/login")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "input" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Zaloguj",
                              sublabel: "Rozpocznij pracę"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn
                    ? _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.$router.push("/login")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "exit_to_app" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Wyloguj",
                              sublabel:
                                "zakończ bieżącą sesję i przejdź do strony logowania"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }