import { render, staticRenderFns } from "./userEmailChangeSimple.vue?vue&type=template&id=e81dbf30&scoped=true&"
import script from "./userEmailChangeSimple.vue?vue&type=script&lang=js&"
export * from "./userEmailChangeSimple.vue?vue&type=script&lang=js&"
import style0 from "./userEmailChangeSimple.vue?vue&type=style&index=0&id=e81dbf30&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e81dbf30",
  null
  
)

component.options.__file = "userEmailChangeSimple.vue"
export default component.exports