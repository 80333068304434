var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-field",
    [
      _c("q-input", {
        ref: "ifMyField",
        attrs: { "float-label": _vm.label, value: _vm.value, type: "textarea" },
        on: { input: _vm.updateValue }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }