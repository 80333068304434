var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoggedIn
        ? _c(
            "q-list",
            [
              _c(
                "q-item",
                { attrs: { multiline: "" } },
                [
                  _c("q-item-side", {
                    attrs: { icon: "person", color: "primary" }
                  }),
                  _c(
                    "q-item-main",
                    { attrs: { inset: "" } },
                    [
                      _c("q-item-tile", { attrs: { label: "" } }, [
                        _vm._v(
                          _vm._s(_vm.getUser_User.firstname) +
                            " " +
                            _vm._s(_vm.getUser_User.lastname)
                        ),
                        _c("br")
                      ]),
                      _c("q-item-tile", { attrs: { sublabel: "" } }, [
                        _vm._v("Imię i nazwisko")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c("q-item-side", {
                    attrs: { icon: "perm_identity", color: "primary" }
                  }),
                  _c(
                    "q-item-main",
                    [
                      _c("q-item-tile", { attrs: { label: "" } }, [
                        _vm._v(_vm._s(_vm.getUser_User.username))
                      ]),
                      _c("q-item-tile", { attrs: { sublabel: "" } }, [
                        _vm._v("login")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c("q-item-side", {
                    attrs: { icon: "phone", color: "primary" }
                  }),
                  _c(
                    "q-item-main",
                    [
                      _c("q-item-tile", { attrs: { label: "" } }, [
                        _vm._v(_vm._s(_vm.getUser_User.phone))
                      ]),
                      _c("q-item-tile", { attrs: { sublabel: "" } }, [
                        _vm._v("telefon")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c("q-item-side", {
                    attrs: { icon: "mail", color: "primary" }
                  }),
                  _c(
                    "q-item-main",
                    [
                      _c("q-item-tile", { attrs: { label: "" } }, [
                        _vm._v(_vm._s(_vm.getUser_User.email))
                      ]),
                      _c("q-item-tile", { attrs: { sublabel: "" } }, [
                        _vm._v("email")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c("q-item-side", {
                    attrs: { icon: "info", color: "primary" }
                  }),
                  _c(
                    "q-item-main",
                    [
                      _c("q-item-tile", { attrs: { label: "" } }, [
                        _vm._v(_vm._s(_vm.getUser_User.description))
                      ]),
                      _c("q-item-tile", { attrs: { sublabel: "" } }, [
                        _vm._v("informacje dodatkowe")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("q-item-separator", { attrs: { inset: "" } }),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-main",
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { rounded: "" } },
                        [
                          _c("q-btn", {
                            attrs: {
                              icon: "edit",
                              color: "primary",
                              rounded: "",
                              label: "Edycja"
                            },
                            on: { click: _vm.editData }
                          }),
                          _c("q-btn", {
                            attrs: {
                              icon: "mail",
                              color: "primary",
                              rounded: "",
                              label: "Zmiana email"
                            },
                            on: { click: _vm.changeEmail }
                          }),
                          _c("q-btn", {
                            attrs: {
                              icon: "phone",
                              color: "primary",
                              rounded: "",
                              label: "Zmiana telefonu"
                            },
                            on: { click: _vm.changePhone }
                          }),
                          _c("q-btn", {
                            attrs: {
                              icon: "security",
                              color: "primary",
                              rounded: "",
                              label: "Zmiana hasła"
                            },
                            on: { click: _vm.changePassword }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("userDataChange", { ref: "thisUserDataChange" }),
      _c("userEmailChangeSimple", { ref: "thisUserEmailChangeSimple" }),
      _c("userPhoneChange", { ref: "thisUserPhoneChange" }),
      _c("userPasswordChange", {
        ref: "thisUserPasswordChange",
        attrs: { mode: "user" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }