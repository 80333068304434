var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout-drawer",
    {
      attrs: { side: "right", overlay: "" },
      model: {
        value: _vm.drawerOpen,
        callback: function($$v) {
          _vm.drawerOpen = $$v
        },
        expression: "drawerOpen"
      }
    },
    [
      _c(
        "q-scroll-area",
        { staticStyle: { width: "100%", height: "100%" } },
        [
          _vm.getAppMode_ICOR
            ? _c(
                "q-list",
                {
                  attrs: {
                    "no-border": "",
                    link: "",
                    dense: "",
                    "inset-separator": ""
                  }
                },
                [
                  _c(
                    "q-collapsible",
                    { attrs: { icon: "assignment", label: "Zadania" } },
                    [
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "9Working")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "assignment" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Lista zadań",
                              sublabel: "w trakcie pracy"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "9Ready")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "assignment" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Lista zadań",
                              sublabel: "oczekujące"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute(
                                "/stats/",
                                "9Processing"
                              )
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "assignment" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Lista zadań",
                              sublabel: "przed uruchomieniem"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "9Deleted")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "assignment" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Lista zadań",
                              sublabel: "zakończone"
                            }
                          }),
                          _c("q-item-side", {
                            attrs: { right: "", icon: "alarm" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "9")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "assignment" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Lista zadań",
                              sublabel: "wszystkie"
                            }
                          }),
                          _c("q-item-side", {
                            attrs: { right: "", icon: "alarm" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-collapsible",
                    { attrs: { icon: "group", label: "Sesje" } },
                    [
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "10")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "group" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Aktywne sesje",
                              sublabel: "wszystkie"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-collapsible",
                    { attrs: { icon: "folder_open", label: "Pliki" } },
                    [
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "6")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "folder_open" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Czas i liczba zapisów",
                              sublabel: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "7")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "folder_open" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Czas i liczba zapisów",
                              sublabel: "plików skopiowanych"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "8")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "folder_open" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Czas i liczba generowań plików",
                              sublabel: "wg ścieżek"
                            }
                          }),
                          _c("q-item-side", {
                            attrs: { right: "", icon: "alarm" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-collapsible",
                    { attrs: { icon: "content_copy", label: "Rozdziały" } },
                    [
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "1")
                            }
                          }
                        },
                        [
                          _c("q-item-side", {
                            attrs: { icon: "content_copy" }
                          }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Czas i liczba generowań",
                              sublabel: "na użytkownika"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "2")
                            }
                          }
                        },
                        [
                          _c("q-item-side", {
                            attrs: { icon: "content_copy" }
                          }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Czas i liczba generowań",
                              sublabel: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "3")
                            }
                          }
                        },
                        [
                          _c("q-item-side", {
                            attrs: { icon: "content_copy" }
                          }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Czas i liczba generowań",
                              sublabel: "z plikami"
                            }
                          }),
                          _c("q-item-side", {
                            attrs: { right: "", icon: "alarm" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "4")
                            }
                          }
                        },
                        [
                          _c("q-item-side", {
                            attrs: { icon: "content_copy" }
                          }),
                          _c("q-item-main", {
                            attrs: { label: "Statusy rozdziałów", sublabel: "" }
                          }),
                          _c("q-item-side", {
                            attrs: { right: "", icon: "alarm" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-collapsible",
                    { attrs: { icon: "cloud_download", label: "Serwisy" } },
                    [
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "5")
                            }
                          }
                        },
                        [
                          _c("q-item-side", {
                            attrs: { icon: "cloud_download" }
                          }),
                          _c("q-item-main", {
                            attrs: { label: "Czas i liczba", sublabel: "" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-collapsible",
                    { attrs: { icon: "storage", label: "Bazy danych" } },
                    [
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "11")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "storage" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Lista aktywnych zadań",
                              sublabel: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "14")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "storage" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Rozmiar bazy danych",
                              sublabel: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "15")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "storage" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Statystyki IO dla tabel",
                              sublabel: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "16")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "storage" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Statystyki dla tabel",
                              sublabel: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-collapsible",
                    { attrs: { icon: "dns", label: "System" } },
                    [
                      _c(
                        "q-item",
                        {
                          attrs: { link: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.setNewTabRoute("/stats/", "12")
                            }
                          }
                        },
                        [
                          _c("q-item-side", { attrs: { icon: "dns" } }),
                          _c("q-item-main", {
                            attrs: {
                              label: "Wolne miejsce na dyskach",
                              sublabel: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }