var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-4 q-pa-sm" },
      [
        _c(
          "q-list",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.markers.length > 0,
                expression: "markers.length>0"
              }
            ],
            attrs: { separator: "", multiline: "", dense: "" }
          },
          _vm._l(_vm.markers, function(item) {
            return _c(
              "q-item",
              { key: item._oid },
              [
                _c(
                  "q-item-main",
                  [
                    _c("q-item-tile", {
                      staticClass: "cursor-pointer",
                      attrs: { label: "" },
                      domProps: { innerHTML: _vm._s(item.title) },
                      nativeOn: {
                        click: function($event) {
                          return _vm.clickMarker(item)
                        },
                        dblclick: function($event) {
                          return _vm.dblClickMarker(item)
                        }
                      }
                    }),
                    _c("q-item-tile", { attrs: { sublabel: "" } }, [
                      _vm._v(_vm._s(item.lat) + " : " + _vm._s(item.lng))
                    ])
                  ],
                  1
                ),
                _c("q-item-side", {
                  staticClass: "cursor-pointer",
                  attrs: { right: "", icon: "close", color: "red" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.deleteMarker(item)
                    }
                  }
                })
              ],
              1
            )
          }),
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "col q-pa-sm" }, [
      _c("div", { ref: "mymap", staticClass: "leaflet-map" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }