var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-toolbar",
        { attrs: { inverted: "" } },
        [
          _c("q-toolbar-title", [
            _vm._v("\n        Załączniki\n        "),
            _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v("\n          zarządzanie załącznikami\n        ")
            ])
          ]),
          _c(
            "q-btn",
            {
              attrs: { flat: "", dense: "", icon: "refresh" },
              on: {
                click: function($event) {
                  return _vm.dataLoad()
                }
              }
            },
            [_c("q-tooltip", [_vm._v("Odśwież listę załączników")])],
            1
          )
        ],
        1
      ),
      _c("br"),
      _c("q-uploader", {
        ref: "uploader",
        attrs: {
          multiple: "",
          url: _vm.getAttachmentsAddURL,
          additionalFields: _vm.uploaderFields,
          "auto-expand": "",
          "stack-label": "Dodaj nowe załączniki"
        },
        on: { finish: _vm.finishUpload }
      }),
      _c("br"),
      _c("br"),
      _c(
        "q-list",
        {
          directives: [
            {
              name: "dragula",
              rawName: "v-dragula",
              value: _vm.dataItems,
              expression: "dataItems"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.itemsExists,
              expression: "itemsExists"
            }
          ],
          ref: "files",
          attrs: { separator: "", multiline: "", bag: "attachments-bag" }
        },
        _vm._l(_vm.dataItems, function(item, index) {
          return _c(
            "q-item",
            { key: item._OID, class: index },
            [
              _c(
                "q-item-side",
                [
                  _c(
                    "q-chip",
                    {
                      staticClass: "handle cursor-pointer",
                      attrs: { color: "primary" }
                    },
                    [
                      _c("q-icon", {
                        staticClass: "handle cursor-pointer",
                        attrs: { color: "bg-primary", name: "assignment" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-item-main",
                [
                  _c(
                    "q-collapsible",
                    {
                      attrs: {
                        label: item.FileName + item.FileExt,
                        sublabel:
                          _vm.humanStorageSize(item.FileSize) +
                          " - " +
                          item._datetime +
                          " - " +
                          item._UserName
                      }
                    },
                    [
                      _c("i-q-field", {
                        attrs: { label: "Nazwa", iscopy: "", ispaste: "" },
                        model: {
                          value: item.FileName,
                          callback: function($$v) {
                            _vm.$set(item, "FileName", $$v)
                          },
                          expression: "item.FileName"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: { label: "Opis", iscopy: "", ispaste: "" },
                        model: {
                          value: item.Description,
                          callback: function($$v) {
                            _vm.$set(item, "Description", $$v)
                          },
                          expression: "item.Description"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Informacja Podmiot Udostepniający",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: {
                          search: _vm.searchInformacjaPodmiotUdostepniajacy
                        },
                        model: {
                          value: item.InformacjaPodmiotUdostepniajacy,
                          callback: function($$v) {
                            _vm.$set(
                              item,
                              "InformacjaPodmiotUdostepniajacy",
                              $$v
                            )
                          },
                          expression: "item.InformacjaPodmiotUdostepniajacy"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Informacja Osoba Odpowiedzialna",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchInformacjaOsobaOdpowiedzialna },
                        model: {
                          value: item.InformacjaOsobaOdpowiedzialna,
                          callback: function($$v) {
                            _vm.$set(item, "InformacjaOsobaOdpowiedzialna", $$v)
                          },
                          expression: "item.InformacjaOsobaOdpowiedzialna"
                        }
                      }),
                      _c("i-q-field-date", {
                        attrs: { label: "Informacja Data Wytworzenia" },
                        model: {
                          value: item.InformacjaDataWytworzenia,
                          callback: function($$v) {
                            _vm.$set(item, "InformacjaDataWytworzenia", $$v)
                          },
                          expression: "item.InformacjaDataWytworzenia"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Informacja Opis Czynności",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchInformacjaOpisCzynnosci },
                        model: {
                          value: item.InformacjaOpisCzynnosci,
                          callback: function($$v) {
                            _vm.$set(item, "InformacjaOpisCzynnosci", $$v)
                          },
                          expression: "item.InformacjaOpisCzynnosci"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Kategoria",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchCategory },
                        model: {
                          value: item.Category,
                          callback: function($$v) {
                            _vm.$set(item, "Category", $$v)
                          },
                          expression: "item.Category"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Rodzaj",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchKind },
                        model: {
                          value: item.Kind,
                          callback: function($$v) {
                            _vm.$set(item, "Kind", $$v)
                          },
                          expression: "item.Kind"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Klucz",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchItemKey },
                        model: {
                          value: item.ItemKey,
                          callback: function($$v) {
                            _vm.$set(item, "ItemKey", $$v)
                          },
                          expression: "item.ItemKey"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Rel",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchItemRel },
                        model: {
                          value: item.ItemRel,
                          callback: function($$v) {
                            _vm.$set(item, "ItemRel", $$v)
                          },
                          expression: "item.ItemRel"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Tytuł",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchItemTitle },
                        model: {
                          value: item.ItemTitle,
                          callback: function($$v) {
                            _vm.$set(item, "ItemTitle", $$v)
                          },
                          expression: "item.ItemTitle"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Typ",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchItemType },
                        model: {
                          value: item.ItemType,
                          callback: function($$v) {
                            _vm.$set(item, "ItemType", $$v)
                          },
                          expression: "item.ItemType"
                        }
                      }),
                      _c("i-q-field", {
                        attrs: {
                          label: "Status",
                          issearchable: "",
                          iscopy: "",
                          ispaste: ""
                        },
                        on: { search: _vm.searchItemStatus },
                        model: {
                          value: item.ItemStatus,
                          callback: function($$v) {
                            _vm.$set(item, "ItemStatus", $$v)
                          },
                          expression: "item.ItemStatus"
                        }
                      }),
                      _c("br"),
                      _c(
                        "q-btn",
                        {
                          attrs: { icon: "save", color: "primary", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.attachmentSave(item._OID)
                            }
                          }
                        },
                        [_vm._v("Zapamiętaj")]
                      ),
                      _c(
                        "q-btn",
                        {
                          ref: "target",
                          refInFor: true,
                          attrs: {
                            icon: "file_download",
                            color: "primary",
                            small: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.downloadAttachment(item)
                            }
                          }
                        },
                        [_vm._v("Pobierz")]
                      ),
                      item.ExternalWWW
                        ? _c(
                            "q-btn",
                            {
                              ref: "target",
                              refInFor: true,
                              attrs: {
                                icon: "content_copy",
                                color: "primary",
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  ;(_vm.selectedItem = item),
                                    (_vm.modalURLClipboard = true)
                                }
                              }
                            },
                            [_vm._v("Skopiuj URL")]
                          )
                        : _vm._e(),
                      _c(
                        "q-btn",
                        {
                          attrs: {
                            icon: "delete",
                            color: "negative",
                            small: "",
                            outline: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeAttachment(item._OID)
                            }
                          }
                        },
                        [_vm._v("Usuń")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-item-side",
                { attrs: { right: "" } },
                [
                  item.Status == ""
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "positive" } }, [
                            _vm._v("A")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Status == "A"
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "positive" } }, [
                            _vm._v(_vm._s(item.Status))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Status == "N"
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "warning" } }, [
                            _vm._v(_vm._s(item.Status))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Status == "U"
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "light" } }, [
                            _vm._v(_vm._s(item.Status))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Status == "B"
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "negative" } }, [
                            _vm._v(_vm._s(item.Status))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Status == "D"
                    ? _c(
                        "q-item-tile",
                        [
                          _c("q-chip", { attrs: { color: "faded" } }, [
                            _vm._v(_vm._s(item.Status))
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "q-modal",
        {
          attrs: { "content-css": { padding: "20px", minWidth: "90vw" } },
          model: {
            value: _vm.modalURLClipboard,
            callback: function($$v) {
              _vm.modalURLClipboard = $$v
            },
            expression: "modalURLClipboard"
          }
        },
        [
          _c("i-q-field", {
            attrs: {
              label: "Link relatywny do załącznika",
              value: _vm.getItemWWWUrl(false),
              iscopy: ""
            }
          }),
          _c("i-q-field", {
            attrs: {
              label: "Link absolutny do załącznika",
              value: _vm.getItemWWWUrl(true),
              iscopy: ""
            }
          }),
          _c("br"),
          _c("q-btn", {
            staticClass: "float-right",
            attrs: { color: "primary", label: "Zamknij" },
            on: {
              click: function($event) {
                _vm.modalURLClipboard = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }