var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-card",
    { attrs: { flat: "" } },
    [
      _vm.title
        ? _c(
            "q-card-title",
            [_vm._v("\n    " + _vm._s(_vm.title) + "\n    "), _vm._t("title")],
            2
          )
        : _vm._e(),
      _vm.separator ? _c("q-card-separator") : _vm._e(),
      _c("q-card-main", [_vm._t("content")], 2),
      _vm.footer ? _c("q-card-separator") : _vm._e(),
      _vm.footer ? _c("q-card-actions", [_vm._t("footer")], 2) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }