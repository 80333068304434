var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-toolbar",
        { attrs: { inverted: "" } },
        [
          _c("q-toolbar-title", [
            _vm._v("\n        Ustawienia i operacje\n        ")
          ])
        ],
        1
      ),
      _c(
        "q-field",
        { attrs: { label: "Szerokość lewego panelu" } },
        [
          _c("q-slider", {
            attrs: { step: 1, min: 2, max: 9, snap: "", markers: "" },
            model: {
              value: _vm.leftRows,
              callback: function($$v) {
                _vm.leftRows = $$v
              },
              expression: "leftRows"
            }
          })
        ],
        1
      ),
      _c("br"),
      _c(
        "q-field",
        { attrs: { label: "Czy wyświetlać przycisk zamykania na zakładkach" } },
        [
          _c("q-toggle", {
            staticClass: "q-ma-xs",
            model: {
              value: _vm.showTabCloseButton,
              callback: function($$v) {
                _vm.showTabCloseButton = $$v
              },
              expression: "showTabCloseButton"
            }
          })
        ],
        1
      ),
      _c("br"),
      _c(
        "q-field",
        { attrs: { label: "Czy wyświetlać linki w nowych zakładkach" } },
        [
          _c("q-toggle", {
            staticClass: "q-ma-xs",
            model: {
              value: _vm.showLinksInNewTab,
              callback: function($$v) {
                _vm.showLinksInNewTab = $$v
              },
              expression: "showLinksInNewTab"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }