var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-toolbar",
        { attrs: { inverted: "" } },
        [
          _c("q-toolbar-title", [
            _vm._v("\n      Informacje\n      "),
            _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v(
                "\n        informacje uzupełniające, parametry i czynności\n      "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "q-list",
        { attrs: { "no-border": "" } },
        [
          _c("q-list-header", [_vm._v("Parametry strony:")]),
          _c(
            "q-item",
            [
              _c("q-item-main", [
                _c("div", { staticClass: "q-table-container" }, [
                  _c("table", { staticClass: "q-table" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "paramname" }, [
                          _vm._v("tryb zakładki:")
                        ]),
                        _c("td", { staticClass: "paramvalue" }, [
                          _vm._v(_vm._s(_vm.mode))
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ],
            1
          ),
          _vm.showDBItem ? _c("q-item-separator") : _vm._e(),
          _vm.showDBItem
            ? _c("q-list-header", [_vm._v("Parametry DB:")])
            : _vm._e(),
          _vm.showDBItem
            ? _c(
                "q-item",
                [
                  _c("q-item-main", [
                    _c("div", { staticClass: "q-table-container" }, [
                      _c("table", { staticClass: "q-table" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("filetype:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.dbitem.filetype))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("type:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.dbitem.type))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("oid:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.dbitem.oid))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("tid:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.dbitem.tid))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("chapterid:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.dbitem.chapterid))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("cmsid:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.dbitem.cmsid))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("poid:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.dbitem.poid))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("thisPageAction:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.dbitem.thisPageAction))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("thisPageTitle:")
                            ]),
                            _c("td", {
                              staticClass: "paramvalue",
                              domProps: {
                                innerHTML: _vm._s(_vm.dbitem.thisPageTitle)
                              }
                            })
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("thisPageSubTitle:")
                            ]),
                            _c("td", {
                              staticClass: "paramvalue",
                              domProps: {
                                innerHTML: _vm._s(_vm.dbitem.thisPageSubTitle)
                              }
                            })
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("thisPageDescription:")
                            ]),
                            _c("td", {
                              staticClass: "paramvalue",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.dbitem.thisPageDescription
                                )
                              }
                            })
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("documentURL:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.dbitem.documentURL))
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.showJobItem ? _c("q-item-separator") : _vm._e(),
          _vm.showJobItem
            ? _c("q-list-header", [_vm._v("Parametry ICOR:")])
            : _vm._e(),
          _vm.showJobItem
            ? _c(
                "q-item",
                [
                  _c("q-item-main", [
                    _c("div", { staticClass: "q-table-container" }, [
                      _c("table", { staticClass: "q-table" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("jobtype:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.jobitem.jobtype))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("CID:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.jobitem.CID))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("OID:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.jobitem.OID))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("brCID:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.jobitem.brCID))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("brOID:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.jobitem.brOID))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("thisPageTitle:")
                            ]),
                            _c("td", {
                              staticClass: "paramvalue",
                              domProps: {
                                innerHTML: _vm._s(_vm.jobitem.thisPageTitle)
                              }
                            })
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("thisPageSubTitle:")
                            ]),
                            _c("td", {
                              staticClass: "paramvalue",
                              domProps: {
                                innerHTML: _vm._s(_vm.jobitem.thisPageSubTitle)
                              }
                            })
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("thisPageDescription:")
                            ]),
                            _c("td", {
                              staticClass: "paramvalue",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.jobitem.thisPageDescription
                                )
                              }
                            })
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("thisPageAction:")
                            ]),
                            _c("td", { staticClass: "paramvalue" }, [
                              _vm._v(_vm._s(_vm.jobitem.thisPageAction))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "paramname" }, [
                              _vm._v("actionLabel:")
                            ]),
                            _c("td", {
                              staticClass: "paramvalue",
                              domProps: {
                                innerHTML: _vm._s(_vm.jobitem.actionLabel)
                              }
                            })
                          ])
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _c("q-item-separator"),
          _c("q-list-header", [_vm._v("Parametry zakładki:")]),
          _c(
            "q-item",
            [
              _c("q-item-main", [
                _c("div", { staticClass: "q-table-container" }, [
                  _c("table", { staticClass: "q-table" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "paramname" }, [_vm._v("id:")]),
                        _c("td", { staticClass: "paramvalue" }, [
                          _vm._v(_vm._s(_vm.tabitem.id))
                        ])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "paramname" }, [
                          _vm._v("name:")
                        ]),
                        _c("td", { staticClass: "paramvalue" }, [
                          _vm._v(_vm._s(_vm.tabitem.name))
                        ])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "paramname" }, [
                          _vm._v("label:")
                        ]),
                        _c("td", {
                          staticClass: "paramvalue",
                          domProps: { innerHTML: _vm._s(_vm.tabitem.label) }
                        })
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "paramname" }, [
                          _vm._v("sublabel:")
                        ]),
                        _c("td", {
                          staticClass: "paramvalue",
                          domProps: { innerHTML: _vm._s(_vm.tabitem.sublabel) }
                        })
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "paramname" }, [
                          _vm._v("actionlabel:")
                        ]),
                        _c("td", {
                          staticClass: "paramvalue",
                          domProps: {
                            innerHTML: _vm._s(_vm.tabitem.actionlabel)
                          }
                        })
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "paramname" }, [
                          _vm._v("url:")
                        ]),
                        _c("td", { staticClass: "paramvalue" }, [
                          _vm._v(_vm._s(_vm.tabitem.url))
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }