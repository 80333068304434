var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-field",
    { attrs: { label: _vm.label, orientation: "vertical" } },
    [
      _c("q-editor", {
        ref: "ifMyField",
        attrs: {
          value: _vm.value,
          "max-height": "45vh",
          definitions: _vm.definitions,
          toolbar: _vm.toolbar,
          fonts: _vm.fonts
        },
        on: { input: _vm.updateValue }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }