var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-toolbar",
        { attrs: { inverted: "" } },
        [
          _c("q-toolbar-title", [
            _vm._v("\n      Kalendarium\n      "),
            _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v(
                "\n        ustawienie zakresu dat i stanu wyświetlania\n      "
              )
            ])
          ])
        ],
        1
      ),
      _c("i-q-field", {
        attrs: { label: "Tytuł" },
        model: {
          value: _vm.dataItem.nazwa,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "nazwa", $$v)
          },
          expression: "dataItem.nazwa"
        }
      }),
      _c("i-q-field-date", {
        attrs: { label: "Data rozpoczęcia" },
        model: {
          value: _vm.dataItem.dataRozpoczecia,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "dataRozpoczecia", $$v)
          },
          expression: "dataItem.dataRozpoczecia"
        }
      }),
      _c("i-q-field-date", {
        attrs: { label: "Data Zakończenia" },
        model: {
          value: _vm.dataItem.dataZakonczenia,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "dataZakonczenia", $$v)
          },
          expression: "dataItem.dataZakonczenia"
        }
      }),
      _c("i-q-field-date", {
        attrs: { label: "Wyświetlaj aktualności od" },
        model: {
          value: _vm.dataItem.wyswietlajAktualnosciOd,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "wyswietlajAktualnosciOd", $$v)
          },
          expression: "dataItem.wyswietlajAktualnosciOd"
        }
      }),
      _c("i-q-field-date", {
        attrs: { label: "Wyświetlaj aktualności do" },
        model: {
          value: _vm.dataItem.wyswietlajAktualnosciDo,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "wyswietlajAktualnosciDo", $$v)
          },
          expression: "dataItem.wyswietlajAktualnosciDo"
        }
      }),
      _c("i-q-field-editor", {
        attrs: { label: "Opis" },
        model: {
          value: _vm.dataItem.opis,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "opis", $$v)
          },
          expression: "dataItem.opis"
        }
      }),
      _c("i-q-field-text-area", {
        attrs: { label: "Uwagi" },
        model: {
          value: _vm.dataItem.uwagi,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "uwagi", $$v)
          },
          expression: "dataItem.uwagi"
        }
      }),
      _c("i-q-field-select", {
        attrs: {
          label: "Priorytet wyświetlania",
          options: _vm.optionsPriorytetWyswietlania
        },
        model: {
          value: _vm.dataItem.priorytetWyswietlania,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "priorytetWyswietlania", $$v)
          },
          expression: "dataItem.priorytetWyswietlania"
        }
      }),
      _c("i-q-field-select", {
        attrs: {
          label: "Priorytet aktualności",
          options: _vm.optionsPriorytetAktualnosci
        },
        model: {
          value: _vm.dataItem.priorytetAktualnosci,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "priorytetAktualnosci", $$v)
          },
          expression: "dataItem.priorytetAktualnosci"
        }
      }),
      _c("i-q-field-select", {
        attrs: {
          label: "Status zatwierdzenia",
          options: _vm.optionsStatusZatwierdzenia
        },
        model: {
          value: _vm.dataItem.statusZatwierdzenia,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "statusZatwierdzenia", $$v)
          },
          expression: "dataItem.statusZatwierdzenia"
        }
      }),
      _c("br"),
      _c(
        "q-btn",
        {
          attrs: { icon: "save", color: "primary", small: "" },
          on: { click: _vm.dataSave }
        },
        [_vm._v(" Zapamiętaj")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }