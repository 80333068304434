var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout",
    { ref: "layout", attrs: { view: "lhr LpR lfr" } },
    [
      _c("q-layout-header", [_c("toolbar")], 1),
      _c("drawer", { ref: "leftDrawer" }),
      _c("drawer-right", { ref: "rightDrawer" }),
      _c(
        "q-page-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.getShowPane_ICOR,
              expression: "getShowPane_ICOR"
            }
          ]
        },
        [_c("manager")],
        1
      ),
      _c(
        "q-page-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.getShowPane_Content,
              expression: "getShowPane_Content"
            }
          ]
        },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }