var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-toolbar",
        { attrs: { inverted: "" } },
        [
          _c("q-toolbar-title", [
            _vm._v("\n      Abstrakt\n      "),
            _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v("\n        tytuł i skrót/streszczenie treści\n      ")
            ])
          ])
        ],
        1
      ),
      _c("i-q-field", {
        attrs: { label: "Tytuł" },
        model: {
          value: _vm.dataItem.tytul,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "tytul", $$v)
          },
          expression: "dataItem.tytul"
        }
      }),
      _c("i-q-field-text-area", {
        attrs: { label: "Treść" },
        model: {
          value: _vm.dataItem.tresc,
          callback: function($$v) {
            _vm.$set(_vm.dataItem, "tresc", $$v)
          },
          expression: "dataItem.tresc"
        }
      }),
      _c("br"),
      _c(
        "q-btn",
        {
          attrs: { icon: "save", color: "primary", small: "" },
          on: { click: _vm.dataSave }
        },
        [_vm._v(" Zapamiętaj")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }