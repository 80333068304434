var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoggedIn
    ? _c(
        "q-modal",
        {
          ref: "thisModal",
          model: {
            value: _vm.modalOpened,
            callback: function($$v) {
              _vm.modalOpened = $$v
            },
            expression: "modalOpened"
          }
        },
        [
          _c(
            "q-stepper",
            { ref: "stepper", attrs: { "no-header-navigation": "" } },
            [
              _c(
                "q-step",
                {
                  attrs: {
                    default: "",
                    title: "Nowy nr telefonu",
                    subtitle: "Numer zostanie zmieniony po jego potwierdzeniu"
                  }
                },
                [
                  _c(
                    "q-list",
                    [
                      _c(
                        "q-item",
                        [
                          _c("q-item-side", {
                            attrs: { icon: "phone_android", color: "primary" }
                          }),
                          _c(
                            "q-item-main",
                            [
                              _c(
                                "q-field",
                                {
                                  attrs: {
                                    helper: "wprowadź nowy nr telefonu",
                                    "error-label": "wymagany nr telefonu"
                                  }
                                },
                                [
                                  _c("q-input", {
                                    attrs: {
                                      autofocus: "",
                                      "float-label": "nowy nr telefonu",
                                      type: "tel",
                                      error: _vm.$v.phone.$error
                                    },
                                    on: { blur: _vm.$v.phone.$touch },
                                    model: {
                                      value: _vm.phone,
                                      callback: function($$v) {
                                        _vm.phone = $$v
                                      },
                                      expression: "phone"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("q-item-separator", { attrs: { inset: "" } })
                    ],
                    1
                  ),
                  _c(
                    "q-stepper-navigation",
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { rounded: "" } },
                        [
                          _c("q-btn", {
                            attrs: {
                              rounded: "",
                              icon: "done",
                              color: "primary",
                              label: "Dalej",
                              disabled: _vm.$v.phone.$error
                            },
                            on: { click: _vm.step1click }
                          }),
                          _c("q-btn", {
                            attrs: {
                              rounded: "",
                              icon: "cancel",
                              color: "negative",
                              label: "Anuluj"
                            },
                            on: { click: _vm.cancelData }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-step",
                {
                  attrs: {
                    title: "Weryfikacja kodu",
                    subtitle: "przekazanego przez SMS"
                  }
                },
                [
                  _c(
                    "q-list",
                    [
                      _c(
                        "q-item",
                        [
                          _c("q-item-side", {
                            attrs: { icon: "lock_open", color: "primary" }
                          }),
                          _c(
                            "q-item-main",
                            [
                              _c(
                                "q-field",
                                {
                                  attrs: {
                                    helper: "wprowadź kod z SMS",
                                    "error-label": "wprowadź kod z SMS"
                                  }
                                },
                                [
                                  _c("q-input", {
                                    attrs: {
                                      autofocus: "",
                                      "float-label": "kod",
                                      type: "text",
                                      error: _vm.$v.kod.$error
                                    },
                                    on: { blur: _vm.$v.kod.$touch },
                                    model: {
                                      value: _vm.kod,
                                      callback: function($$v) {
                                        _vm.kod = $$v
                                      },
                                      expression: "kod"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("q-item-separator", { attrs: { inset: "" } })
                    ],
                    1
                  ),
                  _c(
                    "q-stepper-navigation",
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { rounded: "" } },
                        [
                          _c("q-btn", {
                            attrs: {
                              rounded: "",
                              icon: "done",
                              color: "primary",
                              label: "Dalej",
                              disabled: _vm.$v.kod.$error
                            },
                            on: { click: _vm.step2click }
                          }),
                          _c("q-btn", {
                            attrs: {
                              rounded: "",
                              icon: "cancel",
                              color: "negative",
                              label: "Anuluj"
                            },
                            on: { click: _vm.cancelData }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-step",
                {
                  attrs: {
                    title: "Zmiana nr telefonu",
                    subtitle: "po prawidłowym sprawdzeniu kodu"
                  }
                },
                [
                  _c("h3", [
                    _vm._v("Gratulacje - nr telefonu został zmieniony!")
                  ]),
                  _c(
                    "q-stepper-navigation",
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { rounded: "" } },
                        [
                          _c("q-btn", {
                            attrs: {
                              rounded: "",
                              icon: "cancel",
                              color: "negative",
                              label: "Koniec"
                            },
                            on: { click: _vm.cancelData }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }