import { render, staticRenderFns } from "./cardInfo.vue?vue&type=template&id=48e160c8&scoped=true&"
import script from "./cardInfo.vue?vue&type=script&lang=js&"
export * from "./cardInfo.vue?vue&type=script&lang=js&"
import style0 from "./cardInfo.vue?vue&type=style&index=0&id=48e160c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e160c8",
  null
  
)

component.options.__file = "cardInfo.vue"
export default component.exports