var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getAppMode_ICOR
    ? _c(
        "div",
        {
          staticClass: "row no-wrap justify-between items-start content-stretch"
        },
        [
          _c(
            "div",
            { class: _vm.classLeftRow },
            [
              _c(
                "q-tabs",
                {
                  attrs: {
                    animated: "",
                    swipeable: "",
                    refs: _vm.$refs,
                    align: "justify",
                    "no-pane-border": ""
                  }
                },
                [
                  _c(
                    "q-tab",
                    {
                      attrs: {
                        slot: "title",
                        default: "",
                        name: "tab-1",
                        icon: "device_hub"
                      },
                      slot: "title"
                    },
                    [
                      _c("q-tooltip", [_vm._v("Serwisy i aplikacje")]),
                      _c(
                        "q-popover",
                        {
                          attrs: { disable: !_vm.showHelp },
                          model: {
                            value: _vm.showHelp,
                            callback: function($$v) {
                              _vm.showHelp = $$v
                            },
                            expression: "showHelp"
                          }
                        },
                        [
                          _c("h3", [_vm._v("Serwisy i aplikacje")]),
                          _c("p", [
                            _vm._v("Drzewo aplikacji, rozdziały i linki.")
                          ]),
                          _c("p", [
                            _vm._v(
                              "Każdy użytkownik widzi tylko to, do czego ma uprawnienia"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-tab",
                    {
                      attrs: {
                        slot: "title",
                        name: "tab-2",
                        icon: "extension"
                      },
                      slot: "title"
                    },
                    [_c("q-tooltip", [_vm._v("Repozytorium")])],
                    1
                  ),
                  _c(
                    "q-tab",
                    {
                      attrs: { slot: "title", name: "tab-3", icon: "build" },
                      slot: "title"
                    },
                    [_c("q-tooltip", [_vm._v("System")])],
                    1
                  ),
                  _c(
                    "q-tab",
                    {
                      attrs: { slot: "title", name: "tab-4", icon: "reorder" },
                      slot: "title"
                    },
                    [_c("q-tooltip", [_vm._v("Zakładki")])],
                    1
                  ),
                  _c(
                    "q-tab-pane",
                    { attrs: { name: "tab-1", "keep-alive": "" } },
                    [
                      _c("iframe", {
                        staticClass: "myframe",
                        attrs: {
                          id: "tocpane",
                          src: _vm.urlIFrameTOC,
                          frameborder: "0",
                          width: "100%",
                          marginheight: "0",
                          marginwidth: "0",
                          scrolling: "auto"
                        }
                      })
                    ]
                  ),
                  _c(
                    "q-tab-pane",
                    { attrs: { name: "tab-2", "keep-alive": "" } },
                    [
                      _c("iframe", {
                        staticClass: "myframe",
                        attrs: {
                          id: "Iframe1conf",
                          src: _vm.urlIFrameConf,
                          frameborder: "0",
                          width: "100%",
                          marginheight: "0",
                          marginwidth: "0",
                          scrolling: "auto"
                        }
                      })
                    ]
                  ),
                  _c(
                    "q-tab-pane",
                    { attrs: { name: "tab-3", "keep-alive": "" } },
                    [
                      _c("iframe", {
                        staticClass: "myframe",
                        attrs: {
                          id: "Iframe1system",
                          src: _vm.urlIFrameSystem,
                          frameborder: "0",
                          width: "100%",
                          marginheight: "0",
                          marginwidth: "0",
                          scrolling: "auto"
                        }
                      })
                    ]
                  ),
                  _c(
                    "q-tab-pane",
                    { attrs: { name: "tab-4", "keep-alive": "" } },
                    [_c("card-favorite", { attrs: { tabs: _vm.tabs } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "q-tabs",
                {
                  attrs: {
                    animated: "",
                    swipeable: "",
                    refs: _vm.$refs,
                    align: "left",
                    "no-pane-border": "",
                    "two-lines": ""
                  },
                  model: {
                    value: _vm.tabs.selected,
                    callback: function($$v) {
                      _vm.$set(_vm.tabs, "selected", $$v)
                    },
                    expression: "tabs.selected"
                  }
                },
                [
                  _vm._l(_vm.tabs.items, function(item) {
                    return _c(
                      "q-tab",
                      {
                        key: item.name,
                        attrs: {
                          slot: "title",
                          name: item.name,
                          label: item.label
                            ? _vm.decodeHTML(item.label)
                            : "Dane"
                        },
                        slot: "title"
                      },
                      [
                        _vm.getShow_TabCloseButton
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "accept_click q-chip row no-wrap inline items-center q-chip-floating q-chip-dense q-chip-square tab-chip"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "q-chip-main",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.clickTabClose(item.name)
                                      }
                                    }
                                  },
                                  [_vm._v("X")]
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("q-tooltip", [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(item.label) }
                          }),
                          item.sublabel !== ""
                            ? _c("div", [
                                _c("br"),
                                item.actionlabel
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.actionlabel) + " - ")
                                    ])
                                  : _vm._e(),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(item.sublabel) }
                                })
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.tabs.items, function(item) {
                    return _c(
                      "q-tab-pane",
                      {
                        key: item.name,
                        staticClass: "q-pa-none",
                        attrs: { name: item.name, "keep-alive": "" }
                      },
                      [
                        item.tabtype === ""
                          ? _c("tab-manager", { attrs: { tabitem: item } })
                          : _vm._e(),
                        item.tabtype === "/stats/"
                          ? _c("stats", { attrs: { tabitem: item } })
                          : _vm._e(),
                        item.tabtype === "/user/settings/"
                          ? _c("uzytkownikdane", { attrs: { tabitem: item } })
                          : _vm._e(),
                        item.tabtype === "/app/settings/"
                          ? _c("card-settings", { attrs: { tabitem: item } })
                          : _vm._e()
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              ref: "modalfilemanager",
              staticClass: "maximized",
              attrs: { "content-css": { padding: "50px" } },
              on: {
                open: function($event) {
                  return _vm.notifyFMModal("open")
                },
                "escape-key": function($event) {
                  return _vm.notifyFMModal("escape-key")
                },
                close: function($event) {
                  return _vm.notifyFMModal("close")
                }
              }
            },
            [
              _c("iframe", {
                staticClass: "modal-iframe",
                attrs: { src: _vm.isrc }
              }),
              _c(
                "button",
                {
                  staticClass: "tertiary",
                  on: {
                    click: function($event) {
                      return _vm.$refs.modalfilemanager.close()
                    }
                  }
                },
                [_vm._v("Close Me")]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }